.autosuggestion-input {
    position: relative;
    .autosuggestion-menu-container {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 4px;
        background: #fff;
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
        z-index: 998;
        ul.autosuggestion-menu {
            list-style-type: none;
            margin: 0;
            padding: 0;
            .autosuggestion-option {
                padding: 0.75rem 0.75rem;
                cursor: pointer;
                &:hover {
                    background: #DEEBFF
                }
            }
        }
    }
    .ui.action.input {
        .ui.button {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            i.icon {
                margin: 0!important;
            }
        }
    }
}