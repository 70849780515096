@import 'settings.scss';
/* 

    Vetero App 
    Mixins

    1. Font sizes
    2. Breakpoints
    3. Other

*/

//////////////////////
//  1. Font Sizes   //
//////////////////////

@mixin font-size($type) {
    @if $type == 'small-xs' {
        font-size: $font-s-xs;
        @include breakpoint (tablet){
            font-size: $font-desktop-s-xs;
        }
    }
    @if $type == 'small-s' {
        font-size: $font-s-s;
        @include breakpoint(tablet){
            font-size: $font-desktop-s-s;
        }
    }
    @if $type == 'small-m' {
        font-size: $font-s-m;
        @include breakpoint(tablet){
            font-size: $font-desktop-s-m;
        }
    }
    @if $type == 'small-l' {
        font-size: $font-s-l;
        @include breakpoint(tablet){
            font-size: $font-desktop-s-l;
        }
    }
    @if $type == 'medium-s' {
        font-size: $font-m-s;
        @include breakpoint(tablet) {
            font-size: $font-desktop-m-s;
        }
    }
    @if $type == 'medium-m' {
        font-size: $font-m-m;
    }
    @if $type == 'medium-l' {
        font-size: $font-m-l;
    }
    @if $type == 'large-s' {
        font-size: $font-l-s;
        @include breakpoint(tablet){
            font-size: $font-l-m;
        }
    }
    @if $type == 'large-m' {
        font-size: $font-l-m;
        @include breakpoint(tablet) {
            font-size: $font-desktop-m-l;
        }
    }
    @if $type == "big"{
        font-size: $font-big;
        @include breakpoint(computer) {
            font-size: $font-desktop-big;
        }
    }
    @if $type == 'massive' {
        font-size: $font-massive;
    }
}

//////////////////////
//  2. Breakpoints  //
//////////////////////

@mixin breakpoint($device, $second-device: null) {

    @if($second-device != null){
        //If second device is defined
        @if $second-device == 'tablet' {
            @media (max-width: $tabletBreakpoint) { @content; } // from 320px to 768px
        }
        @if $device == 'tablet' and $second-device == 'computer' {
            @media (min-width: $tabletBreakpoint) and (max-width: $computerBreakpoint) { @content; } // from 768px to 992px
        }
    }
    @else {
        @if $device == 'mobile' { 
            @media(min-width: $mobileBreakpoint) { @content; } // 320px
        }
        @if $device == 'tablet' {
            @media(min-width: $tabletBreakpoint) { @content; } // 768px
        }
        @if $device == 'computer' {
            @media(min-width: $computerBreakpoint) { @content; } // 992px
        }
        @if $device == 'largeMonitor' {
            @media(min-width: $largeMonitorBreakpoint) { @content; } // 1280px
        }
        @if $device == 'wideScreen' {
            @media(min-width: $widescreenMonitorBreakpoint) { @content; } // 1920px
        }
    }

}

//////////////////////
//  3. Other        //
//////////////////////

@mixin image-responsive($object-fit) {
    width: 100%;
    height: 100%;
    object-fit: $object-fit;
    object-position: center;
}

@mixin detailsHeader(){
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    width: 100%;
    @include breakpoint(tablet){
        height: 450px;
    }
}

@mixin box-shadow(){
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

@mixin nav-links() {
    font-family: $header-fontfamily;
    font-size: $fontsize-header-nav;
    color: #fff;
}

@mixin topFixed() {
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

@mixin imgOverlay(){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

@mixin absolute(){
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}