
/* ************* Vetero Global Settings *************** */
/* 

    1. Typography
    2. Font families
    3. Colors
    4. Breakpoints
    5. Others

*/

/* ************* Font sizes *************** */

$h1-fontsize: 1.563rem;
$h2-fontsize: 1.25rem;
$h3-fontsize: 1rem;
$h4-fontsize: 0.8125rem;

$font-s-xs: 0.563rem; //9px
$font-s-s: 0.688rem; //11px
$font-s-m: 0.75rem; //12px
$font-s-l: 0.813rem; //13px

$font-m-xs: 0.875rem; //14px
$font-m-s: 0.938rem; //15px
$font-m-m: 1rem; //16px
$font-m-l: 1.25rem; // 20px

$font-l-s: 1.375rem; //22px
$font-l-m: 1.563rem; //25px

$font-big: 2.125rem;

$font-massive: 3.75rem; //60px

$font-headerimage: 4.688rem; //75px
$font-headerimage-sm: 3.125rem;

$blog-headersize: 3.25rem;

//Fontsizes for medium devices

$font-desktop-s-xs: 0.813rem;
$font-desktop-s-s: 0.813rem;
$font-desktop-s-l: 0.875rem;

$font-desktop-s-m: 0.938rem;
$font-desktop-m-s: 1rem;
$font-desktop-m-l: 1.5rem;

$font-desktop-big: 4.625rem;

$story-mobile-fontsize: 0.813rem;
$story-desktop-fontsize: 1.125rem;

$projects-mobile-fontsize: 1.25rem;
$projects-desktop-fontsize: 1.5rem;

$header-fontsize: 1.375rem;
$fontsize-header-nav: 1rem;
$fontsize-sidebar: 1.125rem;

$card-description-fontsize: 0.813rem;
$card-meta-icons-fontsize: 0.75rem;

$card-meta-icons-fontsize-desktop: 0.938rem;
$card-description-fontsize-desktop: 0.875rem;

$breadcrumb-fontsize: $font-s-l;

///////////////////////
//  2. Font Families //
///////////////////////

$header-fontfamily: 'Barlow Condensed', sans-serif;
$page-fontfamily: 'Barlow', sans-serif;
$header-fontfamily-secondary: 'Teko', sans-serif;

//////////////////////
//  3. Colors       //
//////////////////////

//red
$red-light: #E7344C;
$red-darker: #AC1C2F;;

//dark
$dark-lighter: #1F1308;
$dark: #36220F;

//grey
$grey-lighter: #F7F7F7;
$grey-light: #F3F1ED;
$grey-dark: #B0A187;

//green
$green: #00D83C;

//orange
$orange: #FF9600;

//Backend header color (gradient)
$backend-header-bg-color: $red-light;
$backend-header-bg-secondary: $red-darker;
$backend-edit-header-bg-color: #253341;

//Frontend header color (gradient)
$frontend-header-bg-color: $dark;
$frontend-header-bg-secondary: $dark-lighter;

//Iconbox background color
$iconbox: #D8D2C7;

//Box shadow colors
$box-shadow: 0px 0px 10px -5px rgba(0,0,0,1);
//Input
$input-background: #F5F8FA;
$input-border-color: #CBD6E1;
//////////////////////
//  4. Breakpoints  //
//////////////////////

$mobileBreakpoint: 20rem; // 320px
$tabletBreakpoint: 48rem; // 768px
$computerBreakpoint: 62rem; // 992px
$largeMonitorBreakpoint: 80rem; // 1280px
$widescreenMonitorBreakpoint: 120rem; // 1920px

//////////////////////
//  5. Others       //
//////////////////////

$border-radius: 5px;
$swipeout-btn-size: 24px;
$header-overlay: rgba(21, 13, 6, 0.45);