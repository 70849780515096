@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.ui.card.project-item {
    @include breakpoint(tablet) {
        min-height: 276px;
        height: 276px;
    }
    @include breakpoint(computer){
        min-height: 410px;
        height: 410px;
    }
}

.ui.card.primary {
    margin: 0;
    margin-bottom: 2rem;
    border-radius: 5px;
    width: 100%;
    position: relative;
    overflow: hidden;
    @include box-shadow();
    .card-close-btn {
        &.mobile {
            @include breakpoint(tablet){
                display: none;
            }
        }
        &.desktop {
            display: none;
            @include breakpoint(tablet){
                display: block;
            }
        }
        cursor: pointer;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background-color: #fff;
        padding: 10px;
        border-radius: 100%;
        img {
            width: 14px;
            height: 14px;
        }
    }
    .card-images {
        display: flex;
        height: 100%;
        @include breakpoint(tablet) {
            min-height: 276px;
            height: 276px;
        }
        @include breakpoint(computer){
            min-height: 350px;
            height: 350px;
        }
        .main-image {
            &.full { //If there is only one image...
                flex-basis: 100%;
            }
            flex-basis: 66.66%;
            height: 250px;
            @include breakpoint(tablet){
                min-height: 100%;
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .story-images {
            flex-basis: 33.4%;
            max-height: 250px;
            overflow: hidden;
            @include breakpoint(tablet){
                max-height: 100%;
            }
            .img {
                height: calc(50% - 2px);
                position: relative;
                margin-left: 1px;
                padding-bottom: 100%;
                &:first-child {
                    margin-bottom: 2px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                }
                &.last {
                    position: relative;
                }
            }
        }
        .user-label {
            left: 1.5rem;
            top: 1.5rem;
        }
    }
    .description {
        @include breakpoint(tablet){
            margin-left: 0;
            padding-top: 1.5rem;
            padding-bottom: 0.75rem;
            padding-left: 0;
            padding-right: 0;
        }
        //justify-content: space-around;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 1rem;
        .card-content.logo {
            display: flex;
            
        }
        .card-header {
            &.logo {
                display: flex;
            }
            .card-header-content {
                flex-basis: 80%;
            }
            .card-title {
                .ui.header {
                    text-transform: unset;
                }
            }
            .item-logo {
                margin-left: 0.5rem;
                margin-right: 1rem;
                flex-basis: 20%;
            }
            .description-meta {
                color: $grey-dark;
                font-size: $card-meta-icons-fontsize-desktop;
                font-weight: 500;
            }
        }
        .item-description {
            margin-top: 1.5rem;
            margin-bottom: auto;
            @include breakpoint(tablet){
                margin-right: 1rem;
            }
            &.portrait {
                margin-top: 0;
                .ui.header {
                    margin-bottom: 1.5rem;
                }
            }
            .item-logo-portrait {
                .ui.image {
                    width: 25%;
                    float: right;
                }
            }
        }
        .card-button {
            padding-top: 1rem;
            padding-bottom: 1.063rem;
            text-align: center;
            margin-top: auto;
        }
    }
    .meta.project-info {
        background-color: rgba($grey-light, 0.15);
        display: flex;
        justify-content: space-between;
        .meta-item {
            padding: 0.688rem 1rem;
            img {
                display: inline-block;
                width: 17.5px;
                height: 17.5px;
                @include breakpoint(computer){
                    width: 22px;
                    height: 22px;
                }
            }
            span {
                font-weight: 600;
                color: $dark;
                font-size: $card-meta-icons-fontsize;
                @include breakpoint(computer){
                    font-size: $card-meta-icons-fontsize-desktop;
                }
            }
        }
    }
    .ui.grid {
        .description-column {
            padding-top: 0;
            height: 100%;
            display: block;
            @include breakpoint(tablet){
                padding-top: 1rem;
            }
        }
    }
}

.card.item-card {
    margin-bottom: 1.25rem;
    display: block;
    .item-card {
        position: relative;
        .item-card-image {
            position: relative;
            padding-bottom: 100%;
            @include breakpoint(tablet){
                padding-bottom: 66.6%;
            }
            margin: -1rem;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }
            .ui.label {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                margin-left: 0;
                border: none;
            }
        }
        .item-card-content {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            .item-card-headline {
                font-weight: 600;
                margin-bottom: 1rem;
                padding-right: 1rem;
                font-size:$story-mobile-fontsize;
                @include breakpoint(tablet){
                    font-size: $projects-mobile-fontsize;
                }
            }
            .item-card-subheadline {
                font-weight: 500;
                font-size:$story-mobile-fontsize;
                @include breakpoint(tablet){
                    font-size: $story-desktop-fontsize;
                }
            }
            .professional-createdate {
                font-size: $font-s-m;
                @include breakpoint(tablet){
                    font-size: $font-m-m;
                }
            }
        }
        .item-card-dropdown {
            position: absolute;
            right: 0.5rem;
            top: 0;
            img {
                width: 20px;
                height: 20px;
            }
            .ui.dropdown .menu {
                left: auto;
                right: 0;
                @include breakpoint(computer){
                    left: 0;
                    right: auto;
                }
                .item {
                    background-color: $grey-dark;
                    color: #fff;
                    font-weight: 500;
                    img {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }
    }
    &.fetching {
        .item-card-image {
            margin: -1rem;
            .ui.placeholder {
                .image:not(.header):not(.ui){
                    height: auto;
                    padding-bottom: 66.6%;
                    @include breakpoint(tablet){
                        padding-bottom: 60%;
                    }
                }
            }
        }
        .item-card-content {
            padding: 1rem 0;
            .ui.placeholder {
                .line {
                    background: #F7F7F7;
                }
            }
        }
    }
}

//FeedCard
.card.feed {
    border-radius: 5px;
    box-shadow: 0px 0px 10px -5px #000;
    background-color: #ffffff;
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    .card-header {
        .card-meta {
            font-size: 0.75rem;
            color: $grey-dark;
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;
            font-weight: 600;
            @include breakpoint(tablet){
                font-size: 0.938rem;
            }
        }
    }
    .card-body {
        font-size: 0.813rem;
        line-height: 1.5;
        color: $dark;
        margin-bottom: 1rem;
        @include breakpoint(tablet){
            font-size: 0.875rem;
        }
    }
    .card-image {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: 1rem;
        .main-image, .secondary-image {
            position: relative;
            padding-bottom: 50%;
            cursor: pointer;
        }
        img {
            @include absolute();
            height:100%;
            width: 100%;
            object-fit: cover;
        }
        //Multiple images
        .images {
            display: flex;
            .main-image {
                flex-basis: 70%;
            }
            .secondary-image {
                flex-basis: 30%;
                margin-left: 0.15rem;
            }
        }
    }
    .card-actions {
        display: flex;
        justify-content: space-between;
        .actions {
            .action {
                cursor: pointer;
                img {
                    display: inline-block;
                    margin-right: 0.25rem;
                }
                span {
                    font-size: 0.95rem;
                    font-weight: 600;
                }
                &.comment {
                    font-size: 0.95rem;
                    font-weight: 600;
                }
            }
        }
    }
}
