/* SearchModule - Dropdown list with searching results */

.ui.form input:not([type]) {
    &.error {
        background-color: #fff6f6!important;
        border-color: #e0b4b4;
        color: #9f3a38;
    }
}

input:-webkit-autofill:focus {
    border: #fff
  }


.autosuggestion {
    position: relative;
    margin-bottom: 1em;
    select:-webkit-autofill:focus {
        border: 1px solid green;
        -webkit-text-fill-color: green;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    label {
        font-size: 0.875rem;
        font-weight: 600;
    }
    .autosuggestion-input {
        position: relative;
    }
    .autosuggestion-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        background-color: hsl(0,0%,100%);
        border-radius: 4px;
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
        margin-bottom: 8px;
        margin-top: 8px;
        z-index: 999;
        display: none;
        &.show {
            display: block;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style-type: none;
                padding: 8px 0.875rem;
                &:hover {
                    background-color: #DEEBFF;
                    cursor: pointer;
                }
            }
        }
    }
}