//Functions 
@import '../../css/mixins.scss';
@import '../../css/settings.scss';


.functions-navigation.ui.grid {
    padding-top: 58px;
    padding-bottom: 49px;
    .icon {
        cursor: pointer;
    }
}

//Gifs
.functions-page, .detail-page {
    .gif-element.ui.image {
        box-shadow: 0px 0px 10px #00000029;
        margin-top: 1rem;
        @include breakpoint(tablet){
            margin-right: auto;
            margin-left: auto;
            display: block;
        }
    }
}

.functions-page {
    overflow: hidden;
    margin-bottom: -1rem;
    .header-image {
        background-position: center center;
        @include breakpoint(computer){
            background-position: center;
        }
    }
    h3.ui.header {
        text-transform: unset;
        @include font-size(large-m)
    }
    .introduction-content {
        .ui.button {
            width: 100%;
            @include breakpoint(tablet){
                width: auto
            }
        }
    }
    .iconbox-wrapper {
        .iconbox-item {
            margin-bottom: 2rem;
            display: flex;
            flex-basis: 50%;
            flex-wrap: wrap;
            flex-direction: column;
            @include breakpoint(tablet){
                display: flex;
                flex-basis: 33.3%;
                text-align: left;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                margin-bottom: 1rem;
            }
            .iconbox-icon {
                margin: 0 auto;
                margin-bottom: 1rem;
                @include breakpoint(tablet){
                    width: 45px;
                    height: 45px;
                    padding: 12px;
                    margin-right: 14px;
                    margin-bottom: 0;
                }
            }
            .iconbox-content {
                margin-top: 0;
                width: 100%;
                line-height: unset;
                text-align: center;
                @include breakpoint(tablet){
                    width: calc(100% - 45px);
                    text-align: left;
                }
            }
        }
    }
    .guide-section {
        .ui.grid > .row {
            margin-bottom: 40px;
            @include breakpoint(computer){
                margin-bottom: 80px;
            }
            &.tablet.vertically, &.mobile.vertically {
                flex-direction: column-reverse;
                @include breakpoint(tablet) {
                    flex-direction: row;
                }
            }
        }
        video {
            box-shadow: 0px 0px 10px #00000029;
            margin-top: 1rem;
            width: 100%;
            height: auto;
            @include breakpoint(tablet){
                margin-right: auto;
                margin-left: auto;
                display: block;
            }
        }
        .ui.header {
            text-align: center;
            @include breakpoint(tablet) {
                text-align: left;
            }
        }
        .mobile-link {
            display: none;
            text-align: center;
            margin-top: 2rem;
            @include breakpoint(tablet){
                display: none;
            }
        }
        .desktop-link {
            display: none;
            /*@include breakpoint(tablet){
                display: block;
            }*/
        }
        .desktop-link.story-link {
            display: none;
            @include breakpoint(tablet){
                display: block;
            }
        }
        .mobile-link.story-link {
            display: block;
            @include breakpoint(tablet){
                display: none;
            }
        }
    }
    .closing-section.iconbox-wrapper {
        .iconbox {
            margin-left: -1rem;
            margin-right: -1rem;
        }
        .iconbox-item {
            flex-basis: 100%;
            flex-wrap: nowrap;
            flex-direction: row;
            margin-bottom: 2rem;
            align-items: flex-start;
            padding-left: 1rem;
            padding-right: 1rem;
            @include breakpoint(tablet){
                flex-basis: 50%;
                margin-bottom: 38px;
                .iconbox-icon {
                    width: 70px;
                    height: 70px;
                    margin-right: 20px;
                    display: flex;
                    img {
                        padding: 6px
                    }
                }
                .iconbox-content {
                    line-height: unset;
                    text-align: center;
                    .iconbox-link {
                        margin-top: 1rem;
                        font-weight: 600;
                    }
                }
            }
            .iconbox-icon {
                padding: 14px;
                margin-right: 14px;
                @include breakpoint(tablet){
                    margin-right: 1.25rem;
                }
            }
            .iconbox-content {
                text-align: left;
                width: calc(100% - 70px);
                .iconbox-header {
                    text-transform: uppercase;
                    font-size: 1.25rem;
                    font-family: 'Barlow Condensed';
                }
            }
        }
    }
    .pwa-guide {
        padding: 2rem 0;
    }
}

.functions-page.detail-page {
    .closing-text {
        span {
            color: $red-light;
            font-size: $header-fontsize;
            font-family: $header-fontfamily;
            font-weight: 500;
            text-transform: uppercase;
            display: block;
            margin-bottom: 6px;
        }
    }
    .iconbox-wrapper {
        background: #fff;
        .ui.header {
            text-align: center;
            margin-bottom: 2rem;
            @include breakpoint(tablet) {
                text-align: left;
            }
        }
    }
}

