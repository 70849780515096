//follower.js
@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.ui.message.dashboard-message-top {
   display: flex;
   align-items: center;
   font-weight: 500;
   margin-bottom: 0;
   @include breakpoint(tablet){
       padding-top: 1rem;
       padding-bottom: 1rem;
       margin-bottom: 1rem;
       .dashboard-message {
            font-size: 0.938rem;;
       }
   }
   .dashboard-message-close {
        margin-left: auto; 
        margin-top: 4px; //Center icon
        i.icon {
            font-size: 1rem;
            margin-right: 0;
            cursor: pointer;
        }
   }
}

.feedback-wrapper {
    .ui.basic.button {
        font-weight: 500;
    }
    .ui.button {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        width: calc(50% - 0.5rem);
        button {
            width: 100%;
        }
    }
    .ui.labeled.button {
        .label {
            width: 100%;
            text-align: center;
            display: inline-block;
            padding: 10px 0;
        }
    }
    .ui.button.red.basic {
        text-align: left;
        color: $dark!important;
        padding-left: 10px;
        font-size: $font-s-l;
        min-width: calc(100% - 2rem);
    }
}


.your-projects-wrapper {
    .ui.header {
        padding-top: 1rem;
        @include breakpoint(tablet){
            padding-top: 0;
        }
    }
    .project {
        padding: 0 0.5rem;
        text-align: center;
    }
    @include breakpoint(tablet){
        .slick-list {
            margin-left: -0.5rem;
            margin-right: -0.5rem
        }
    }
}

.dashboard-projects-slideshow {
    .ui.header {
        @include breakpoint(tablet){
            padding-left: 1rem;
        }
    }
    .project {
        padding: 0 0.5rem;
        text-align: center;
    }
    @include breakpoint(tablet){
        .slick-list {
            margin-left: 0.5rem;
            margin-right: 0.5rem
        }
    }
}

//Placeholder while fetching...
.dashboard-slider-placeholder {
    .ui.header {
        @include breakpoint(computer){
            padding-left: 1rem;
        }
    }
    .ui.grid {
        @include breakpoint(tablet){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        .column:not(row){
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    .your-projects-wrapper {
        .ui.header {
            @include breakpoint(computer){
                padding-left: 0;
            }
        }
        .ui.grid {
            @include breakpoint(tablet){
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }
    .slides-placeholder.ui.placeholder {
        width: 100%;
        border-radius: 100%;
        padding-bottom:100%;
        @include breakpoint(tablet){
            padding-bottom: 0;
            height: 150px;
            border-radius: 0;
            padding: 0 0.5rem;
        }
        .image:not(.header):not(.ui) {
            position: absolute;
            height: 100%;
            box-sizing: border-box;
            @include breakpoint(tablet){
                height: 150px;
            }
        }
        .line {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;        
        } 
    }
}

//Follower.js

.followers {
    .ui.feed > .event:last-child > .content {
        margin-top: 0;
        .summary {
          
                @include font-size(medium-s);
            
        }
    }
    .ui.feed > .event > .content {
        font-size: 13px;
        .meta {
            font-size: 13px;
            margin-top: 0;
            .label {
                margin-left: 0.5rem;
            }
            * {
                margin-left: 0;
            }
        }
        .meta.follower-title {
            display: block;
            .label {
                margin-top: 0.5rem;
            }
            .follower-title-project {
                font-weight: 700;
            }
        }
        .meta.follower-date {
            @include font-size(small-l);
            font-family: $page-fontfamily;
            position: absolute;
            top: 7px;
            right: 14px;
            @include font-size(small-l);
        }
    }
    .ui.feed > .event > .label {
        width: 50px;
        height: 50px;
        img {
            height: 100%;
        }
    }
}

//DashboardComments.js
//Comments SlidingPane 
.comments-dashboard{
    //Placeholder
    .comments-dashboard-placeholder {
        margin-bottom: 1rem!important;
        .comments-dashboard-placeholder-img.ui.placeholder {
            height: 40px;
            width: 40px;
            border-radius: 100%;
            float: left;
            margin-right: 0.5rem;
        }
        .ui.placeholder .image:not(.header):not(.ui) {
            // height: 40px;
            // width: 40px;
            // display: inline-block;
            // float: left;
            // line-height: 1;
        }
        .ui.placeholder.comments-dashabord-placeholder-content {
           
            .line {
                background-color: $grey-lighter;
            }
        }
        .ui.placeholder + .ui.placeholder  {
            margin-top: 0;
        }
        
    }
    //User header
    .ui.card > .content > .header:not(.ui) {
        @include font-size(medium-s);
        font-family: $page-fontfamily;
        display: inline-block;
        .comment-type {
            margin: 0.25rem 0;
        }
    }
    .ui.card {
        width: 100%;
        background: $grey-lighter;
        border-bottom: none;
        padding: 10px 0;
        .ui.divider {
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;
        }
        .ui.image {
            width: 50px;
            height: 50px;
            display: inline-block;
            float: left;
            margin-right: 13px;
        }
        .content {
            border-bottom: none;
            padding-top: 3px;
            padding-bottom: 3px;
            .description.comment-describtion, .comment-date {
                @include font-size(small-l);
                font-family: $page-fontfamily;
            }
            .comment-date {
                position: absolute;
                top: 7px;
                right: 14px;
                @include font-size(small-l);
            }
            .description.comment-describtion {
                clear: none;
                margin: 0.2em;
                line-height: 1rem;
            }
           
            // line-height: 1rem;
            .meta.comment-user-project, .meta.comment-user-description {
                @include font-size(small-l);
                line-height: 1rem;
                color: rgba($dark, 0.5);
                font-weight: normal;
            }
            .meta.comment-user-project {
                font-weight: 700;
            }
            .meta.comment-user-project {
                color: $dark
            }
        }
    }
}

//OwnProjects.js
.ui.segment.own-projects-list {
    &:last-child {
        margin-bottom: 1rem;
    }
    box-shadow: none;
    .item-description {
        .ui.header {
            text-transform: uppercase;
        }
    }
    .item-img {
        position: relative; 
        height: 100px;
        @include breakpoint(tablet){
            height: 150px;
        }
        .ui.image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        .ui.label {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
            @include font-size(medium-s)
        }
    }
    .ui.grid.item-info {
        .column:not(.row){
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            &:first-child {
                padding-left: 1rem;
            }
        }
        margin-top: 0.5rem;
        @include breakpoint(tablet){
            margin-top: 1rem;
        }
        @include font-size(small-l);
        .item-info-content {
            margin: 0.25rem 0!important;
        }
        .ui.image {
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

//Voucher
//Mobile voucher segment
.voucher-wrapper {
    .column {
        margin-bottom: 1rem;
    }
    .voucher-notfound {
        @include font-size(medium-s);
    }
}
.voucher.ui.card {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    height: 100%;
    @include box-shadow();
    @include breakpoint(tablet){
        margin-right: 1rem;
        margin-left: 1rem;
        background: transparent;
    }
    .ui.header {
        background-color: $grey-light;
        margin-top: -1rem;
        margin-left: -1rem;
        margin-right: -1rem;
        padding: 1rem;
    }
    .voucher-description {
        @include font-size(small-l); 
    }
    
    .voucher-image {
        text-align: center;
        .ui.image {
            margin: 0 auto;
        }
    }
    .voucher-code {
        font-weight: bold;
        text-align: center;
        font-size: $font-m-l;
        margin-bottom: 1rem;
    }
    .voucher-date {
        text-align: center;
        font-size: $font-s-l;
    }
    .voucher-image-placeholder {
        width: 100px;
        height: 100px;
    }
    .voucher-buttons {
        margin-top: auto;
        .ui.button {
            margin-right: 0.33rem;
        }
    }
}

//New notification label
.new-notification {
    &.new-comment {
        top: 40%;
        transform: translateY(-100%);
    }
    background: $red-light;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    border-radius: 100%;;
}

//Forum
.ui.segment.thread {
    .ui.feed {
        .event {
            .content {
                display: flex;
                justify-content: space-between;
                .thread-title {
                    font-weight: bold;
                }
                .meta {
                    margin: 0;
                    .thread-posts-count {
                        .ui.image {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            margin-right: 0.25rem;
                            @include breakpoint(tablet){
                                width: 16px;
                                height: 16px;
                            }
                        }
                        font-weight: 600;
                        margin-left: auto;
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
}

//Professional list 
.professionals-userlist {
    .rc-swipeout {
        overflow: unset;
    }
    .custom-plus-button.ui.button {
        margin: 2rem 0;
    }
}

.professional-edit-header {
    .last-edit {
        line-height: 2;
        white-space: nowrap;
        text-align: right;
        font-weight: bold;
        @include font-size(small-s)
    }
}

