/*

    Vetero - helper classes
    1. Text
    2. Fonts
    3. Other

*/

@import './settings.scss';
@import './mixins.scss';

body {
    margin: 0;
    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }



  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

.app-reload {
    font-weight: 500;
    .manuall {
        margin-top: 0.5rem;
        font-size: 1rem;
    }
    .ui.button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }
}

.is-editing {
    input[type="text"], textarea, .tox .tox-edit-area iframe, input {
        background-color: $input-background!important;
        border-color: $input-border-color!important;
    }

    .custom-select__value-container {
        background-color: $input-background!important;
    }

    .error.field input[type="text"], 
    .error.field textarea, 
    .error.field .tox .tox-edit-area iframe,
    .error.field .custom-select__value-container  {
        border-color: rgba($red-darker, 0.3)!important;
        background-color: rgba($red-light, 0.1)!important;
    }

}

.ui.form .fields {
    margin-bottom: 1rem;
}

.add-to-home-banner {
    z-index: 999999;
    background: rgba(0, 0, 0, 0.9)!important;
    padding: 1rem;
    color: #fff!important;
    font-weight: bold;
    .add-to-home-icon {
        padding: 0!important;
        width:40px!important;
        height: 40px!important;
    }
}

//////////////////////
//  1. Text         //
//////////////////////

.text-grey {
    color: $grey-dark;
}

.text-dark {
    color: $dark;
}

.text-red {
    color: $red-darker
}

.text-light {
    color: #fff;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-transform-normal {
    text-transform: none!important;
}

//////////////////////
//  2. Fonts        //
//////////////////////

.fontsize-small-xs {
    @include font-size(small-xs);
}

.fontsize-small-s {
    @include font-size(small-s);
}

.fontsize-small-m {
    @include font-size(small-m);
}

.fontsize-small-l {
    @include font-size(small-l);
}

.fontsize-medium-s {
    @include font-size(medium-s);
}

.fontsize-medium-m {
    @include font-size(medium-m);
}

.fontsize-medium-l {
    @include font-size(medium-l);
}

.fontsize-large-s {
    @include font-size(large-s);
}

.fontsize-large-m {
    @include font-size(large-m);
}

.fontsize-massive {
    @include font-size(massive);
}

.font-medium {
    font-weight: 500;
}

.font-semi {
    font-weight: 600;
}

.font-bold {
    font-size: bold;
}

.font-header-family {
    font-family: 'Barlow Condensed', sans-serif;
}

.font-family-page {
    font-family: 'Barlow Regular', sans-serif;
}

//////////////////////
//  3. Other        //
//////////////////////

.icon-social {
    width: 1rem;
    height: 1rem;
}

.no-margin-y {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.user-img {
    width: 40px;
    height: 40px;
}

.clearfix {
    clear: both;
}

.no-scroll {
    position: relative;
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.vertical-divider {
    height: 100%;
    width: 1px;
    background-color: rgba($dark, 0.2);
}

.buttons-center {
    width: 100&;
    text-align: center;
    .ui.button {
        display: inline-block;
        width: calc(50% - 1rem);
        text-align: center;
    }
}

.button-element {
    .ui.button {
        padding: 0;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0.8rem 0.5rem!important;
        text-align: left;
        white-space: normal;
        &.single-button {
            width: 45%;
        }

        .ui.image {
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-right: 0.5rem;
            @include breakpoint(tablet) {
                width: 40px;
                height: 40px;
            }
        }
        span, div {
            color: $dark;
            font-weight: 500;
            font-size: $font-m-m;
            @include breakpoint(tablet){
                line-height: 2;
            }
        }
        div {
            white-space: pre-line;
            padding-top: 0.1rem;
        }
    }
}

.button-icon {
    width: 34px;
    height: 34px;
    float: left;
}

.checkbox-wrapper {
    span {
        font-size: $font-s-l;
        font-weight: 500;
    }
}

.input-title {
    font-weight: 500;
}

.icon-newsfeed-social {
    width: 18px;
    height: 18px;
}

.icon-comment-input {
    width: 100%;
    height: 100%;
}

//Select input
.select-input-column.ui.selection.dropdown {
    min-width: 11em;
    margin-left: auto;
}

//Grid Padding Gutter
.ui.grid .grid-gutter-x-left {
    &.column:not(.row), .ui.grid > .row > .column {
        padding-left: 0.5rem
    }
}

.ui.grid .grid-gutter-x-right {
    &.column:not(.row), .ui.grid > .row > .column {
        padding-right: 0.5rem;
    }
}

//Image pencil edit
.edit-img {
    position: relative;
    .icon {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: rgba(255, 255, 255, 0.85);
    }
}

//Input icon
.input-icon.ui.image {
    width: 28px;
    height: 28px;
    display: inline;
    margin-left: 14px;
}

//Input small
.ui.form .field .ui.input.input-sm {
    width: 30%;
}

//custom icon
.custom-icon {
    width: 34px;
    height: 34px;
}

//custom label

//Segment text
.segment-text {
    font-size: $font-s-l;
}

//Input Labels
label.custom-label {
    display: block;
    margin: 1.5rem 0 0.5rem 0;
    font-weight: 600;
    // font-size: $h4-fontsize;
    font-size: 0.875rem;
    color: $dark
}

.input-label {
    font-weight: 600;
    font-size: 0.875rem;
    color: $dark
}

//Icon image global class
.image_container {
    position: relative;
    width: 100%;
    height: 100%;
    i.inverted.bordered.grey.icon, i.inverted.circular.grey.icon {
        background-color: rgba(#FFFFFF, 0.85)!important;
        color: $dark!important;
    }
    i.icon {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    &.main_img {
        .main_img-inside {
            width: 100%;
            height: 125px;
            @include breakpoint(tablet){
                width: 100%;
                height: 230px;
            }
        }
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &.preview_img {
        width: 125px;
        height: 125px;
        @include breakpoint(tablet){
            width: 180px;
            height: 180px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
        }
        i.icon {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            margin:0 auto;
        }
    }
}

//Icon image global class - placeholder
.project-image-placeholder {
    .ui.placeholder.preview_img {
        width: 125px;
        height: 125px;
        margin: 0 auto;
        border-radius: 100%;
        @include breakpoint(tablet){
            width: 180px;
            height: 180px;
        }
    }
}

//Circular checkbox
.checkbox-circular-wrapper {
    position: absolute;
    top:50%;
    transform: translateY(-50%);

    .ui.checkbox input[type="checkbox"] {
        width: 25px;
        height: 25px;
    }

    .ui.checkbox label:before {
        border-radius: 100%;
        width: 25px;
        height: 25px;
    }
    .ui.checkbox input:checked {
        background: $red-light;
    }
    .ui.checkbox .box:after, .ui.checkbox label:after {
        background: $red-light;
        border-radius: 100%;
        width: 25px;
        height: 25px;
    }
    .ui.checkbox label:after {
        color: #fff!important;
        line-height: 1.8;
    }
    .ui.checkbox input:active ~ label,
    .ui.checkbox label:hover, .ui.checkbox + label:hover,
    .ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
        color: #fff;
    }

}

//Custom tab menu
.custom-tab-menu {
    margin-top: 10px;
    @media(min-width: 768px){
        margin-top: 1.5rem;

    }
    .ui.menu {
        border-left: none;
        border-right: none;
        border-radius: 0;
        font-weight: bold;
        margin-left: 1rem;
        margin-right: 1rem;
        @media(min-width: 768px){
            margin-left: auto;
            margin-right: auto;
            width: 400px;
        }
    }
    .ui.menu > .item:first-child {
        border-radius: 0;
    }
    .ui.menu.pointing {
        .active.item  {
            &:after {
                background: $dark;
            }
            &:before {
                background: $dark;
            }
        }
        .item {
            flex: 1 0 auto;
            border-radius: none;
            padding: 0;
        }
        &:not(.vertical) .item {
            vertical-align: middle;
            justify-content: center;
            flex: 1 1 0px;
        }
    }
    .ui.attached.segment {
        border: 0!important;
        padding-left: 0;
        padding-right: 0;
        background: none;
        box-shadow: none;
    }
}

//Swipeout Button height fix
.rc-swipeout {
    overflow: unset;
}

.rc-swipeout-btn {
    margin-top: 1px;
    @include breakpoint(tablet){
        margin-right: 1px;
    }
    .rc-swipeout-btn-text {
        width: 100%;
        height: 100%;
        padding: 0;
        position: relative;
        .swipeout-img-container {
            width: 100%;
            height: 100%;
            position: relative;
            .swipeout-img {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: translateY(-50%);
                img {
                    margin: 0 auto;
                    //Standard width & height
                    width: $swipeout-btn-size;
                    height: $swipeout-btn-size;
                }
                span {
                    display: block;
                    padding-top: 0.5rem;
                }
            }
        }
    }
}
.rc-swipeout-actions-right {
    margin-bottom: 1px;
}

.header-small {
    font-size: $font-s-l;
    font-weight: 600;
}

.ui.header.header-normal {
    text-transform: none;
}

//Breadcrumb
.ui.breadcrumb.custom-breadcrumb {
    font-weight: 600;
    a, .divider {
        color: $dark;
    }
}

//Responsive image - object fit
.responsive.ui.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-wrapper-basic {
    line-height: 2;
    .user-image-circular {
        float: left;
        margin-right: 0.5rem;
    }
    span {
        font-weight: bold;
        font-size: 0.85rem;
    }

}

//User Image circular
.user-image-circular {
    width: 42px;
    height: 42px;
    .ui.image {
        width: 100%;
        height: 100%;
    }
}

//Image count overlay
.image-count-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    .image-count {
        @include font-size(big);
        font-family: $header-fontfamily;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        color: rgba(255, 255, 255, 0.75);
        text-align: center;
        transform: translateY(-50%);
        // @include breakpoint(tablet){
        //     margin-top: -60px;
        // }
    }
}
//Social buttons
.social-buttons {
    padding: 0.5rem 0;
    white-space: nowrap;
    .icons-social {
        .icon-social {
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-right: 0.25rem;
            @include breakpoint(tablet) {
                width: 22px;
                height: 22px;
            }
        }
        .social-amount {
            display: inline-block;
            @include font-size(small-m);
            font-weight: 500;
        }
    }
    .column {
        @media(min-width: 768px){
            text-align: center;
        }
    }
}

.image-container-fullsize {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1rem;
}

//Slick Slider - custom settings


.slick-track {
    margin-left: 0;
    margin-right: 0;
}


.slick-arrow {
    display: none;
}
.slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 1rem;
    li {
        margin: 0;
        width: 15px;
        @include breakpoint(computer){
            button:before {
                font-size: 8px;
            }
        }
    }
}

//Placeholder
.text-placeholder {
    opacity: 0.6;
}

//Error label (validation)
.error-label {
    color: $red-darker!important;
}

.link {
    color: $red-light;
    &:hover {
        color: $red-darker;
    }
}

// Edit text
.message-text, .text {
    i.inverted.bordered.grey.icon, i.inverted.circular.grey.icon {
        background-color: rgba(#FFFFFF, 0.85)!important;
        color: $dark!important;
        cursor: pointer;
    }
    i.iconedit {
        position: absolute;
        top: 20px;
        right: 10px;
    }
}
.fullWidth {
    width: 100%;
}

/* Override tinymce default stylesheets */

/* Emojis popup */
.tox.tox-silver-sink.tox-tinymce-aux {
    z-index: 99999;
}

.popup-new-version {
    position: fixed;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 9999;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0rem;
    @include breakpoint(tablet){
        top: 84px;
        background: transparent;
        padding: 0;

    }
    .popup-new-version-inside {
        display: flex;
        border-radius: 8px;
        background:transparent;
        width: 100%;
        padding: 0;
        @include breakpoint(tablet){
            margin-left: auto;
            width: 400px;
            border-radius: 8px;
            padding: 1rem;
            background: rgba(0, 0, 0, 0.9);
        }
        @include breakpoint(largeMonitor){
            margin-right: -5rem;
        }
    }
    cursor: pointer;
    .app-icon {
        margin-right: 1rem;
        img {
            width: 50px;
            height: 50px;
        }
    }
    .new-version-msg {
        color: #fff;
        font-weight: bold;
        p {
            margin-bottom: 0;
        }
    }
}