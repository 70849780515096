@import '../../../css/mixins.scss';
@import '../../../css/settings.scss';

.lineheight1 {
    line-height: 1 !important;
}

.prevent-scroll {
    /* Disables pull-to-refresh but allows overscroll glow effects. */
    overscroll-behavior-y: contain;
}

.msg-count-label {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    @include breakpoint(computer){
        margin-left: auto;
        padding: 0.33rem 0.85rem;
    }
}

.messages-placeholder{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0;
    padding-top: 1rem;
    .messages-placeholder-image.ui.placeholder {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        float: left;
        margin-right: 0.5rem;
        .image:not(.header):not(.ui){
            height: 50px;
        }
    }
    // .ui.placeholder {
    //     .image {
    //         max-width: 50px;
    //         height: 50px;
    //     }
    // }
    .ui.placeholder + .ui.placeholder {
        margin-top: 0;
    }
}

.message-inner-placeholder {
    padding-top: 4rem;
    .message-inner {
        margin: 0;
        margin-bottom: 1rem;
        .messages-placeholder-image.ui.placeholder {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            float: left;
            margin-right: 0.5rem;
            .image:not(.header):not(.ui){
                height: 100%;
                width: 100%;
            }
        }
        &.own-message-placeholder {
            width: 90%;

        }
    }
}

.messages-section {
    /* Disables pull-to-refresh but allows overscroll glow effects. */
     overscroll-behavior-y: contain;
     padding: 0 0.5rem;
     @include breakpoint(tablet){
        padding: 0 1.5rem;
     }
     .ui.grid {
         @include breakpoint(computer){
            .coversations-inner.column:not(.row){
                padding-right: 0;
                height: 100%;
            }
         }
     }
    .msg-header{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        position: fixed;
        left: 0;
        right: 0;
        top: 48px;
        @include breakpoint(tablet){
            top: unset;
        }
        width: 100%;
        background: #fff;
        z-index: 9999;
        border-bottom: 1px solid rgba($grey-dark, 0.4);
        @include breakpoint(tablet){
            position: absolute;
        }
        @include breakpoint(computer){
            z-index: 1;
        }
        &.msg-header-placeholder {
            padding-left: 1rem;
            .messages-placeholder-image {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                float: left;
                margin-right: 0.5rem;
                .image:not(.header):not(.ui){
                    height: 100%;
                    width: 100%;
                }
            }
            .ui.placeholder + .ui.placeholder {
                margin-top: 0.2rem;
            }
        }
        @include breakpoint(tablet){
            padding-top: 0;
        }
        .user-img {
            float: left;
            margin-right: 0.5rem;
            margin-left: 1rem;
            @include breakpoint(tablet){
                margin-right: 1rem;
            }
            .ui.image {
                @include image-responsive(unset);
                line-height: 2;
            }
        }
        span {
            line-height: 2.5;
            font-weight: 600;
            font-size: $font-desktop-m-s;
        }

    }
    .chat-list {
        .chat-list-user-img {
            margin-right: 0.3rem;
            .ui.avatar.image {
                margin-right: 0!important;
            }
        }
    }
    .ui.divider {
        clear: both;
    }
    //Conversation...
    .ui.list.chat-conversation {
        padding-top: 5rem;
        padding-bottom: 5rem;
        @include breakpoint(tablet){
            padding-bottom: 7rem;
        }
        @include breakpoint(computer){
            height: 70vh;
            overflow-x: hidden;
            overflow-y: scroll;
            padding-right: 1rem;
            padding-bottom: 0rem;
        }
        .own-message, .incoming-message {
            min-width: 51%;
            .own-message-content, .incoming-message-content {
                min-width: 35%;
                margin-bottom: 1rem;
                border-radius: 5px;
                display: inline-block;
            }
        }
        .own-message {
            .own-message-content {
                .message-text {
                    background-color: $grey-dark;
                    color: #fff;
                    padding: 0.5rem 2rem 0.5rem 0.5rem;
                    border-radius: 5px;
                    position: relative;

                    i {
                        font-size: 14px;
                        top: 3px;
                        right: 0;
                    }
                }
                float: right;
                max-width: 90%;
                .message-date {
                    margin-top: 0.25rem;
                    font-size: $font-s-m;
                    color: rgba($dark, 0.5);
                    font-weight: 500;
                }
            }
        }
        .incoming-message {
            clear: both;
            display: inline-block;
            .user-img {
                margin-right: 1rem;
                float: left;
                .ui.image {
                    @include image-responsive(unset);
                }
            }
            .incoming-message-content {
                .message-date {
                    margin-top: 0.25rem;
                    font-size: $font-s-m;
                    color: rgba($dark, 0.5);
                    font-weight: 500;
                }
                .message-text {
                    padding: 0.5rem;
                    border-radius: 5px;
                }
            }
            .incoming-message-content {
                .message-text {
                    background-color: $grey-lighter;
                }
                max-width: 75%;
            }
        }
    }
    //Chat list...
    .ui.list.list-msg {
        .item {
            position: relative;
            padding: 1rem 0.5rem;
            @include breakpoint(tablet){
                padding: 1rem 1.5rem;
            }
            @include breakpoint(computer){
                padding: 0.75rem 0.5rem;
                margin-bottom: 0.25rem;
                border-radius: 4px;
                display: flex;
            }
            .content {
                display: block;
                .header {
                    color: $dark;
                    font-weight: 500;
                    font-size: $font-m-m;
                    font-family: $page-fontfamily;
                    line-height: 1.5;
                    // line-break: anywhere;
                    @include breakpoint(tablet){
                        color: $grey-dark;
                    }
                    @include breakpoint(computer){
                        line-height: 2;
                    }
                }
                .message-preview, .message-date {
                    font-size: $font-s-l;
                    color: $dark;
                }
                .message-date {
                    color: rgba($dark, 0.5)
                }
            }
            img.image {
                width: 50px;
                height: 50px;
                float: left;
                display: block;
                margin-right: 0.5rem;
                @include breakpoint(tablet) {
                    width: 50px;
                    height: 50px;
                }
                @include breakpoint(computer){
                    width: 32px;
                    height: 32px,
                }
            }
            &:hover {
                cursor: pointer;
                background-color: $grey-light;
                border-top: 1px solid transparent;
                &:first-child {
                    border-top: none;
                }
                .header {
                    color: $dark;
                }
            }
            &.active  {
                background-color: $grey-light;
                border-top: 1px solid transparent;
            }
        }
        a {
            &.active-msg {
                .item {
                    cursor: pointer;
                    background-color: $grey-light;
                    border-top: 1px solid transparent;
                    &:first-child {
                        border-top: none;
                    }
                    .header {
                        color: $dark;
                    }
                }
            }
        }
    }
    .send-msg-wrapper {
        position: fixed;
        bottom: -1rem;
        left: 0;
        right: 0;
        border-top: 1px solid rgba($grey-dark, 1);
        background: #fff;
        display: flex;
        align-items: flex-start;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include breakpoint(tablet) {
            margin-left: 1rem;
            margin-right: 1rem;
            bottom: 2rem;
            // border: 1px solid rgba($grey-dark, 1);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
        }
        @include breakpoint(computer){
            position: absolute;
            // padding: 1rem 0;
            border: none;
            bottom: -2rem;
            margin: 0;
        }
        .textfield {
            flex: 1;
            flex-basis: 75%;
            margin-left: 0.5rem;
            @include breakpoint(tablet) {
                flex-basis: 80%;
            }
            textarea {
                border: 1px solid rgba($grey-dark, 0.4);
                border-radius: 4px;
                resize: none;
                width: 100%;
                &:focus {
                    outline: none;
                }

                @include font-size(small-l);
                padding: 0.625rem;
            }
        }

        .send-msg-button {
            flex: 2;
            text-align: center;
            cursor: pointer;
            .ui.button {
                padding: 0;
                margin-right: 0;
            }
            .ui.loading.button {
                width: 100%;
                height: 40px;
            }
            .ui.image {
                width: 40px;
                height: 40px;
                margin: 0 auto;
                padding: 0.25rem;
                @include breakpoint(tablet){
                    padding: 0rem;
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }
}
