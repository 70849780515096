@import './settings.scss';
@import './mixins.scss';

/* ************* Theme CSS File / Overrides *************** */
/* 

    1. Global
    2. Typography
    3. Forms
    4. Others

*/

/* ************* Global *************** */

/* ************* Typography *************** */

/* ************* Forms *************** */
.ui.form {
    .field {
        margin-bottom: 1rem;
        @include breakpoint(tablet){
            margin-bottom: 1.5rem;
        }
    }
}

.tooltip {
    cursor: pointer;
}

label + .ui.message {
    margin-top: 0;
}

/* ************* Others *************** */