@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.header-image {
    height: calc(100vh - 48px - 58px);
    @include breakpoint(tablet){
        height: 70vh;
    }
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    &.detail {
        @include detailsHeader;
        .image {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    &.img-overlay {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $header-overlay;
            height: 100%;
            width: 100%;
        }
    }
    .header-image-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(calc(-50% - 58px)); // Mobile: minus footer height 
        color: #fff;
        @include breakpoint(tablet){
            transform: translateY(-50%);
        }
        .ui.header, h1, h2, h3 {
            color: #fff;
            font-family: $header-fontfamily-secondary;
            font-size: $font-headerimage-sm;
            @include breakpoint(computer){
                font-size: $font-headerimage;
            }
        }
        p, span {
            font-size: 1.125rem;
            @include breakpoint(computer){
                font-size: 1.75rem;
            }
        }
    }
    .detail-info-bar {
        background-color: $grey-light;
        @include breakpoint(tablet) {
            background-color: transparent;
        }
        .ui.container {
            padding-top: 1rem;
            border-radius: $border-radius;
            @include breakpoint(tablet){
                padding: 1rem;
                background-color: $grey-light;
                @include box-shadow();
            }
        }
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include breakpoint(tablet){
            bottom: 30px;
        }
        .detail {
            white-space: nowrap;
            &>div {
                display: inline-block;
                vertical-align: sub;
            }
            svg {
                .st0 {
                    fill: none;
                    stroke: $dark;
                }
                width: 18px;
                height: 18px;
                margin-right: 0.15rem;
                @include breakpoint(tablet){
                    width: 22px;
                    height: 22px;
                    margin-right: 0.35rem;
                }
            }
            .active {
                svg {
                    fill: $red-light;
                    .st0 {
                        stroke: $red-light;
                        fill: $red-light;
                    }
                    .st1 {
                        fill: $red-light;
                    }
                }
            }
            &.detail-like, &.detail-comment {
                cursor: pointer;
            }
            .ui.image {
                width: 18px;
                height: 18px;
                display: inline-block;
                margin-right: 0.15rem;
                @include breakpoint(tablet){
                    width: 22px;
                    height: 22px;
                    margin-right: 0.35rem;
                }
            }
            .detail-sum {
                font-weight: 500;
                @include font-size(small-m);
            }
        }
    }
}