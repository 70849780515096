@import '../../../css//mixins.scss';
@import '../../../css//settings.scss';

.new-project {
    .ui.header {
        .header-info-span {
            text-transform: none;
            font-family: 'Barlow', sans-serif;
            font-size: 0.75rem;
        }
    }
    .ui.message {
        padding: 0.438rem 0.625rem;
    }
}

.progress-bar {
    margin: 15px 0;
    height: 40px;
    width: 40px;
}

.top-header {
    padding: 0.625rem 0;
    background: $grey-lighter;
    @include breakpoint(tablet){
        text-align: center;
    }
    span {
        font-weight: lighter;
        color: rgba(0, 0, 0, 0.6);
        @include font-size(medium-s)
    }
}
.ui.icon.input {
    i.circular.icon.input-icon {
        width: 18px!important;
        height: 18px!important;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.input-info {
    font-size: 0.875rem;
    color: $grey_dark;
}

.new-projekt-next-btn {
    text-align: center;
    margin: 40px 0;
    bottom: 0;
    left: 0;
    right: 0;
    .ui.button {
        width: 110px;
    }
}

.new-model-popup-content {
    border-radius: 6px;
    .popup-header, .popup-content {
        .ui.header {
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    .popup-header{
        background-color: rgba($grey-dark, 0.15);
        padding: 15px 13px;
    }
    .popup-content {
        padding-left: 13px;
        padding-right: 13px;
        .ui.input {
            width: 100%;
        }
        .popup-btn-container {
            margin-top: 2rem;
            margin-bottom: 1rem;
            text-align: center;
            .ui.button {
                width: 40%;
                margin: 0
            }
            .ui.basic.button {
                font-weight: 600;
            }
        }
    }
}

.new-project-main-img {
    width: 100%;
    height: 350px;
    @include breakpoint(tablet){
        width: 100%;
        height: 500px;
    }
    img {
        @include image-responsive(cover);
    }
}
