/* stories.scss */

@import '../../css/mixins.scss';
@import '../../css/settings.scss';

/* ************************************** */
/*
    List of components
    1. fe-content-element.js
    
*/
/* ************************************** */


/* **************************************

    1. fe-content-element.js

****************************************** */

.story-element {
    h2.ui.header {
        text-transform: none;
        margin-bottom: 0.5rem;
        @include breakpoint(tablet){
            margin-bottom: 1rem;
        }
    }
    &:not(.text-element) {
        .storys-description {
            display: block;
            padding-bottom: 1rem;
            @include breakpoint(tablet){
                padding-bottom: 2rem;
            }
        }
    }
    .storys-description {
        font-size: 0.875rem;
        @include breakpoint(tablet){
            font-size: 1rem;
        }
    }
    img {
        cursor: pointer;
    }
    &.image-element, &.text-image-element, &.before-after-element {
        .ui.image {
            @include image-responsive(cover);
            height: 200px;
            @include breakpoint(tablet){
                height: 300px;
            }
            @include breakpoint(computer){
                height: 455px;
            }
        }
    }
    //Text element
    &.text-element {
        &.no-headline {
            padding-top: 0;
        }
        &.only-headline {
            padding-bottom: 0;
        }
    }
    //Text/Image
    &.text-image-element {
        .ui.header {
            margin-top: 0.5rem;
            @include breakpoint(tablet){
                margin-top: 0;
            }
        }
        &.image-left + &.image-right, &.image-right + &.image-left {
            @include breakpoint(tablet){
                margin-top: -104px; //Stick to previous image
            }
        }
        @include breakpoint(tablet){
            .ui.grid {
                .column.image-left-description {
                    padding-left: 2rem;
                }
                .column.image-right-description {
                    padding-right: 2rem;
                }
            }
        }
        .no-headline {
            .storys-description {
                padding-top: 0.5rem;
            }
        }
    }
    //Image element
    &.image-element {
        img {
            width: 100%;
            cursor: pointer;
        }
    }
    &.before-after-element {
        .ui.image {
            cursor: pointer;
            @include image-responsive(cover);
            height: 140px;
            @include breakpoint(tablet){
                height: 250px;
            }
            @include breakpoint(computer){
                height: 320px;
            }
        }
        .before-text, .after-text {
            font-size: $font-m-s;
            text-align: center;
            font-weight: 600;
            margin-top: 0.5rem;
        }
        .ui.grid {
            &:first-child {
                &.column:not(.row){
                    padding-right: 0.25rem;
                }
            }
            &.column:not(.row){
                padding-right: 0.25rem;
            }
            .column:not(.row) {
                &:first-child {
                    padding-right: 1px;
                }
            }
            .column:not(.row) {
                &:last-child {
                    padding-left: 1px;
                }
            }
        }
    }
    //Gallery element
    &.gallery-element{
        .story-gallery-wrapper {
            cursor: pointer;
        }
        overflow: hidden;
        @include breakpoint(tablet){
            .top-image {
                width: calc(50% - 2px);
                float: left;
                height: 270px;
                overflow: hidden;
            }
            .images-wrapper {
                float: right;
                width: calc(50% - 2px);
                overflow: hidden;
                height: 270px;
            }
            .top-image, .images-wrapper {
                .ui.image {
                    @include image-responsive(cover);
                }
            }
        }
        @include breakpoint(computer){
            .top-image {
                height: 350px;
            }
            .images-wrapper {
                height: 350px;
            }
        }
        .middle-images {
            margin-top: 3px;
            @include breakpoint(tablet) {
                margin-top: 0;
            }
            .image {
                display: inline-block;
                width: calc(33.3% - 2px);
                margin-left: 1.5px;
                margin-right: 1.5px;
                height: 115px;
                .ui.image {
                    @include image-responsive(cover);
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                @include breakpoint(tablet) {
                    height: 135px;
                }
                @include breakpoint(computer) {
                    height: 175px;
                }
            }
        }
        .bottom-images {
            margin-top: 3px;
            .image {
                display: inline-block;
                width: calc(50% - 2px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                height: 130px;
                @include breakpoint(tablet) {
                    height: 135px;
                }
                @include breakpoint(computer) {
                    height: 175px;
                }
                .ui.image {
                    @include image-responsive(cover);
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
                .image-count-overlay {
                    margin-left: 1px;
                    margin-right: -1px;
                }
            }
            .last {
                position: relative;
                .ui.image {
                    @include image-responsive(cover);
                }
            }
        }
        //Two images gallery
        .two-images-gallery {
            .top-image {
                height: 160px;
            }
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                height: 160px;
                @include breakpoint(tablet){
                    height: auto;
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .three-images-gallery {
            .top-image {
                height: 200px;
                @include breakpoint(tablet){
                    height: 320px;
                }
                @include breakpoint(computer){
                    height: 400px;
                }
            }
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                @include breakpoint(tablet){
                    height: calc(160px - 1.5px);
                }
                @include breakpoint(computer){
                    height: calc(200px - 1.5px);
                }
                &:first-child {
                    margin-left: 0px;
                    @include breakpoint(tablet){
                        height: calc(160px - 1.5px);
                        margin-left: 1.5px;
                        margin-top: 0;
                    }
                    @include breakpoint(computer){
                        height: calc(200px - 1.5px);
                        margin-left: 1.5px;
                        margin-top: 0;
                    }
                }
                &:last-child {
                    margin-right: 0px
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .four-images-gallery {
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
                @include breakpoint(tablet){
                    height: 200px;
                }
                @include breakpoint(computer){
                    height: 300px;
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .five-images-gallery {
            .top-images {
                .image {
                    display: inline-block;
                    width: calc(50% - 2px);
                    margin-right: 1.5px;
                    margin-left: 1.5px;
                    margin-top: 3px;
                    height: 120px;
                    @include breakpoint(tablet){
                        height: 170px;
                    }
                    @include breakpoint(computer){
                        height: 300px;
                    }
                }
            }
            .image {
                display: inline-block;
                width: calc(33.3% - 2px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                @include breakpoint(tablet){
                    height: 100px;
                }
                @include breakpoint(computer){
                    height: 250px;
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
            .last {
                position: relative;
    
            }
        }
    }
    //Video element
    &.video-element {
        cursor: pointer;
        .video-preview {
            position: relative;
            .ui.image {
                @include image-responsive(cover);
                height: 200px;
                @include breakpoint(tablet){
                    height: 300px;
                }
                @include breakpoint(computer){
                    height: 400px;
                }
            }
            i.icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: 5rem;
                height: auto;
            }
        }
    }
}