@import '../../css/mixins.scss';

.user-breadcrumb {
    display: flex;
    align-items: center;
    .user-image {
        width: 40px;
        height: 40px;
        margin-right: 0.5rem;
        @include breakpoint(tablet){
            margin-right: 0.5rem;
        }
        .ui.image {
            border-radius: 100%;
            width: 100%;
            height: 100%;
        }
    }
    .breadcrumb-metadate {
       @include font-size(small-m);
       line-height: 1;
    }
}

.ui.breadcrumb.custom-breadcrumb {
    @include font-size(small-m);
    font-weight: 600;
    a, .divider {
        color: $dark;
    }
}