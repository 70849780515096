/* Frontend.scss */

@import '../../../css/mixins.scss';
@import '../../../css/settings.scss';

/* ************************************** */
/*
    List of components
    1. projectsList - ProjectListGallery.js
    2. projectCard - ProjectCard.js
    
*/
/* ************************************** */

/* ************************************** */
/*
    1. projectsList - ProjectListGallery.js  
*/
/* ************************************** */
.gallery-view-loader {
    padding: 3rem;
    position: relative;
}
.gallery-view {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    margin-left: -1px;
    margin-right: -1px;
    .row {
        transition: 300ms;
        //Fetching data placeholder
        .ui.placeholder {
            height: 100%;
            width: 100%;
            margin: 2px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .image {
                height: 100%!important;
                border: 2px solid #fff;
            }
        }
        &.reversed {
            flex-direction: row-reverse;
        }
        img.ui.image {
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1px;
            cursor: pointer;
        }
        .project-item {
            position: relative;
        }
        display: flex;
        &.default-row {
            .project-item {
                flex-basis: 33.3%;
                padding-bottom: 33%;
                @include breakpoint(tablet){
                    //4 items per row
                    padding-bottom: 24%;
                    flex-basis: 25%;
                }
                @include breakpoint(largeMonitor){
                    padding-bottom: 16.665%;
                    flex-basis: 16.665%;
                }
            }
        }
        &.half {
            .left, .project-item {
                flex-basis: 50%;
            }
            .right {
                flex-basis: 50%;
                .project-item {
                    height: 100%;
                }
            }
        }
        &.full {
            .left, .project-item {
                flex-basis: 100%;
            }
        }
        .left {
            flex-basis: 66.6%;
           .project-item {
                position: relative;
                padding-bottom: 100%;
                
           }
           @include breakpoint(tablet){
                flex-basis: 50%;
            }
           @include breakpoint(largeMonitor){
            display: flex;
            .project-item {
                flex-basis: 50%;
                padding-bottom: 50%;
            }
        }
        }
        .right {
            flex-basis: 33.3%;
            @include breakpoint(tablet){
                flex-basis: 50%;
            }
            .project-item {
                position: relative;
                height: 50%;
                @include breakpoint(tablet){
                    flex-basis: 50%;
                    height: calc(50% - 2px);
                }
            }
            .top-image {
                height: 50%;
                @include breakpoint(largeMonitor){
                    display: flex;
                    .project-item {
                        flex-basis: 50%;
                    }
                }
                .project-item {
                    height: 100%;
                }
                &.full {
                    height: 100%;
                }
            }
            .bottom-images {
                display: flex;
                height: 50%;
                .project-item {
                    flex-basis: 50%;
                    height: 100%;
                }
                &.one-image {
                    .project-item {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}

.project-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    height: 100vh;
    .project-lightbox-close {
        position: fixed;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        i.icon {
            color: #fff;
        }
    }
    .project-card-wrapper {
        position: fixed;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 9999;
    }
}

/* ************************************** */
/*
    1. projectCard - ProjectCard.js
*/
/* ************************************** */
.ui.card.project-item {
    margin: 0;
    margin-bottom: 2rem;
    border-radius: 5px;
    width: 100%;
    position: relative;
    overflow: hidden;
    @include breakpoint(tablet) {
        min-height: 276px;
        height: 276px;
    }
    @include breakpoint(computer){
        min-height: 410px;
        height: 410px;
    }
    @include box-shadow();
    .card-close-btn {
        &.mobile {
            @include breakpoint(tablet){
                display: none;
            }
        }
        &.desktop {
            display: none;
            @include breakpoint(tablet){
                display: block;
            }
        }
        cursor: pointer;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background-color: #fff;
        padding: 10px;
        border-radius: 100%;
        img {
            width: 14px;
            height: 14px;
        }
    }
    .project-images {
        display: flex;
        .main-image {
            &.full { //If there is only one image...
                flex-basis: 100%;
            }
            flex-basis: 66.66%;
            max-height: 250px;
            @include breakpoint(tablet){
                max-height: 100%;
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .story-images {
            flex-basis: 33.4%;
            max-height: 250px;
            overflow: hidden;
            @include breakpoint(tablet){
                max-height: 100%;
            }
            .img {
                height: calc(50% - 2px);
                position: relative;
                margin-left: 1px;
                padding-bottom: 100%;
                &:first-child {
                    margin-bottom: 2px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                }
                &.last {
                    position: relative;
                }
            }
        }
        .user-label {
            left: 1.5rem;
            top: 1.5rem;
        }
    }
    .meta.project-info {
        background-color: rgba($grey-light, 0.15);
        display: flex;
        justify-content: space-between;
        .meta-item {
            padding: 0.688rem 1rem;
            img {
                display: inline-block;
                width: 17.5px;
                height: 17.5px;
                @include breakpoint(computer){
                    width: 22px;
                    height: 22px;
                }
            }
            span {
                font-weight: 600;
                color: $dark;
                font-size: $card-meta-icons-fontsize;
                @include breakpoint(computer){
                    font-size: $card-meta-icons-fontsize-desktop;
                }
            }
        }
    }
    .ui.grid {
        .description-column {
            padding-top: 0;
            height: 100%;
            display: block;
            @include breakpoint(tablet){
                padding-top: 1rem;
            }
        }
    }
    .description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-right: 1rem;
        margin-left: 1rem;
        @include breakpoint(tablet){
            margin-left: 0;
            padding-top: 1rem;
            padding-bottom: 0.75rem;
        }
        .user-label {
            position: static;
            margin-bottom: 1rem;
            .user-name {
                background: #fff;
                color: $dark;
                padding-left: 2.5rem; 
            }
        }
        .project-title {
            margin-bottom: 1rem;
            @include breakpoint(tablet){
                margin-bottom: 6px;
            }
            .ui.header {
                text-transform: unset;
            }
        }
        .project-description {
            font-size: $card-description-fontsize;
            @include breakpoint(computer){
                font-size: $card-description-fontsize-desktop;
            }
        }
    }
    .card-button {
        padding-top: 1rem;
        padding-bottom: 1.063rem;
        text-align: center;
    }
}
