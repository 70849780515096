.error-message-wrapper.ui.message {
    &:first-child {
        margin-top: 3rem;
        margin-bottom: 3rem
    }
    margin-bottom: 3rem;
    text-align: center;
    padding: 2rem 0;
    p {
        font-size: 1rem;
        font-weight: 500;
    }
    i.error-icon {
        font-size: 2.25rem;
    }
    .ui.button {
        margin-top: 1rem;
    }
}