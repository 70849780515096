@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.login-popup-content {
    padding: 1.25rem 0;
    max-width: 400px;
    @include breakpoint(tablet){
        padding: 1.25rem 1rem;
    }
    .popup-content-inside {
        overflow: hidden!important;
    }
    .logo {
        width: 50px;
        height: 50px;
        margin: 0 auto;
    }
    .ui.header.login-header {
        margin-top: 0.33rem;
        font-size: 1.625rem
    }
    .pw-remind {
        color: $dark;
        text-decoration: underline;
        font-weight: 500;
        font-size: 0.875rem;
        cursor: pointer;
    }
    .ui.red.button.submit {
        margin-top: 1rem;
        text-align: center;
    }
    .divider {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 0.875rem;
        font-weight: 600;
        .line {
            width: 100%;
            height: 1px;
            background-color: $dark;
            position: relative;
        }
        .or-wrapper {
            position: absolute;
            top: -12px;
            text-align: center;
            margin: 0 auto;
            width: 100%;
            span {
                background-color: #fff;
                padding: 0 0.5rem;
            }
        }

    }
    .socials-login {
        .ui.button {
            width: 100%;
            margin: 0.33rem 0;
            .ui.image {
                float: left;
                width: 30px;
                height: 30px;
            }
            span {
                text-align: center;
                display: block;
                color: #fff;
                line-height: 2;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    .create-account {
        display: block;
        margin-top: 1rem;
        font-size: 0.875rem;
        text-align: center;
        .create-account-link {
            font-weight: 600;
            color: $red-light;
            cursor: pointer;
        }
    }
}

.ui.form {
    .field.register-checkbox {
        .field {
            display: inline-block;
            label {
                display: block;
            }
        }
        label.register-checkbox-label {
            display: inline-block;
            margin-left: 0.25rem;
            vertical-align: text-bottom;
            line-height: 1;
            font-weight: 500!important;
        }
    }
}

