@import '../../css/mixins.scss';

//Override semantic UI 
.pushable:not(body) {
    transform: none;
}
//Sidebar overlay 
.pushable > .pusher:after {
    content: "";
    background: rgba(0, 0, 0, 0.85)!important;
}

//Mobile nav
.custom-sidebar.ui.menu.left.sidebar.secondary, .custom-sidebar.ui.menu.secondary {
    background: white;
    padding-top: 14px;
    @include breakpoint(computer){
        padding-top: 0;
        background-color: rgba($grey-dark, 0.2);
        border-radius: 4px;
        width: 15rem;
    }

    .ui.header {
        padding: 0 12px 0 12px;
        margin-bottom: 0;
        font-size: $fontsize-sidebar;
        @include breakpoint(computer){
            padding-left: 0.875rem;
            padding-right: 0.875rem;
            padding-top: 0.875rem;
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
    }
    a.item {
        padding: 0 1rem;
        line-height: 1.75;
        font-size: $font-desktop-s-l;
        .item-wrapper {
            padding: 10px 0;
            border-bottom: 1px solid rgba($dark, 0.1);
            overflow: hidden;
        }
        .ui.label {
            font-size: 1rem;
            border-radius: 12px;
            float: right;
            padding: 0.33rem 0.85rem;
        }
        &:hover, &:active, &.active {
            background-color: rgba($dark, 0.1);
            .item-wrapper {
                border-bottom: 1px solid transparent;
            }
        }
    }

    .ui.label.forum-label {
        padding: 0.3833rem 0.533em
    }

    .sidebar-divider {
        width: 100%;
        height: 1px;
        background: #fff;
    }

}

.pushable:not(body) {
    transform: none;
}

.sidebar-menu {
    @media (min-width: "992px"){
        // padding: 1rem;
        .ui.header, .item {
            
            margin-left: 0!important;
            margin-right: 0!important;
        }
    }
}


