@import '../../../../css/mixins.scss';
@import '../../../../css/settings.scss';


/* ************************************** 

    Stories - List of components:
    1. storys.js
    2. NewStory.js
    3. EditStory.js
    4. fe-content-element.js

****************************************** */

/* **************************************

    Storys.js

****************************************** */

.storys {
    @include breakpoint(tablet){
        margin-bottom: 2rem;
    }
    &.storys-placeholder {
        margin-bottom: 1rem;
        @include breakpoint(tablet){
            margin-bottom: 2rem;
        }
    }
    .ui.grid {
        .stories-info-wrapper.column {
            padding-left: 0;
            @include breakpoint(tablet) {
                padding-left: 1rem;
            }
        }
    }
    .ui.segment {
        box-shadow: none;
        @include breakpoint(tablet) {
            padding: 0;
        }
        .storys-img {
            position: relative;
            width: 100%;
            height: 100px;
            @include breakpoint(tablet) {
                width: 250px;
                height: 170px;
            }
            @include breakpoint(computer){
                width: 260px;
            }
            .ui.placeholder {
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            .label {
                position: absolute;
                bottom: 0;
                text-align: center;
                width: 100%;
                margin: 0;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
        .storys-description {
            font-size:$story-mobile-fontsize;
            @include breakpoint(tablet){
                font-size: $story-desktop-fontsize;
                font-weight: 500;
                padding-top: 1rem;
            }
            .ui.placeholder {
                .line {
                    background-color: $grey-lighter;
                }
            }
        }
        .stories-info{
            font-size: 11px;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 0.5rem;

            .column {
                padding: 0 0 0 1rem;
            }

            .stories-feed {
                display: inline-block;
            }
            .storys-icon {
                display: inline-block;
                &.ui.image {
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.33rem;
                }
            }
            @include breakpoint(tablet){
                font-size: 14px;
                position: absolute;
                bottom: 2rem;
                margin-top: 0;
                width: 90%;

                .grid {
                    width: 100%;
                }
            }
            span {
                display: inline-block;
                margin-right: 1rem;
                line-height: 1;
                color: $grey-dark;
            }
        }
    }
}

/* **************************************

    NewStory.js

****************************************** */

.new-story {
    padding-top: 1rem;
}


/* **************************************

    EditStory.js

****************************************** */

.edit-story {
    margin-top: 1rem;
    .ui.form.placeholder-form {
        .field > label {
            opacity: 0.5;
        }
    }
}

//Edit story image
.story-main-img {
    width: 100%;
    height: 230px;
    @include breakpoint(tablet){
        height: 467px;
    }
}

.add-element-btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &.btn-placeholder {
        opacity: 0.5;
    }
}

/* **************************************

    fe-content-element.js

****************************************** */


//Edit new elements
.edit-before-after-element {
    .edit-before-after-element-content {
        .image_container {
            .before-after-img {
                width: 100%;
                @include breakpoint(tablet){
                    height: 250px;
                }
                img {
                    @include image-responsive(cover)
                }
            }
        }
    }
}

.edit-image-element, .edit-video, .edit-text-image-element {
    .image_container {
        @include breakpoint(computer){
            width: 500px;
            margin: 0 auto;
        }
    }
    .ui.image {
        width: 100%;
        height: 100%;
    }
}

.storys-content {

    //Before / After element
    &.before-after {
        .before-after-image {
            height: 120px;
            @include breakpoint(tablet){
                height: 180px;
            }
            @include breakpoint(computer){
                height: 200px;
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .before-after-header {
            text-align: center;
            margin-top: 0.25rem;
            @include font-size(small-l);
            font-weight: 500;
        }
    }

    .more-images {
        display: inline-block;
        font-size: 1.75rem;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba($grey-dark, 0.2);
        vertical-align: middle;
        position: relative;
        color: rgba($dark, 0.15);
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            font-weight: 500;
        }
    }
    .story-content-galerie-imgs.ui.grid {
        padding: 1rem 0;
        .column:not(.row) {
            padding: 0.25rem;
        }
        .content-galerie-wrapper {
            height: 60px;
            @include breakpoint(tablet){
                height: 80px;
            }
            @include breakpoint(computer){
                height: 100px;
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
    }

}

.stories-text-images {
    clear: both;
}

//Content elements dropdown menu - desktop
.content-element-wrapper {
    position: relative;
    top: 0;
    cursor: pointer;
    .content-element-menu {
        position: absolute;
        top: 0rem;
        right: 1rem;
        line-height: 1;
        .content-element-menu-icon-wrapper {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background: #fff;
            padding: 2px;
        }
        .content-element-menu-icon.ui.image {
            height: 100%;
            width: 100%;
            cursor: pointer;
        }
    }
    .delete-element {
        background: $red-light;
        color: #fff!important;
    }
    .sort-btn, .delete-element {
        .ui.image {
            width: 25px;
            height: 25px;
        }
    }
}

.text-image {
    position: relative;
    margin-bottom: 1rem;
    .image-description {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: $font-s-l;
        padding: 0.33rem 0.5rem;
    }
}

.more-stories {
    background-color: rgba($grey-lighter, 0.2);
    .project-storys-wrapper {
        margin-top: 0.5rem;
    }
}

//Confirm leave popup / slidingpane
.modal-confirm-leave.ui.modal {
    .header {
        color: #fff;
    }
    .content {
        @include font-size(medium-s);
        margin-top: 0;
    }
    .actions {
        text-align: center;
    }
}

//Sortable item - disappearing item while dragging fix
.sortableHelper {
    z-index: 9999;
}
