@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.custom-select__menu {
    overflow: hidden;
    position: absolute;
    z-index: 9999;
}

.custom-select__value-container {
    font-size: 0.85rem!important;
}

.custom-select__menu {
    z-index: 999999!important;
}

.custom-select-wrapper {
    // z-index: 2;
    .custom-select__menu {
        z-index: 9999;
    }
    .custom-select__option {
        cursor: pointer;
    }
}

.custom-select-container {
    font-size: $font-m-s;
    // z-index: 2;
    @include breakpoint(computer){
        font-size: 1rem!important;
    }
}