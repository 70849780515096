@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.iconbox {
    display: flex;
    flex-wrap: wrap;
    .iconbox-item {
        display: flex;
        flex-basis: 100%;
        @include breakpoint(tablet){
            flex-basis: 33.3%;
        }
        .iconbox-icon {
            margin-right: 14px;
            padding: 19px;
            width: 64px;
            height: 64px;
            background-color: $iconbox;
            border-radius: 100%;
            margin-bottom: 41px;
            img {
                height: 100%;
                width: 100%;
            }
            @include breakpoint(tablet){
                width: 70px;
                height: 70px;
                padding: 15px;
            }
        }
        .iconbox-content {
            width: calc(100% - 64px);
            font-size: 0.813rem;
            margin-top: 5px;
            line-height: 3;
            @include breakpoint(tablet){
                width: calc(100% - 70px);
                font-size: 1rem;
            }
            .iconbox-header {
                font-weight: 600;
                margin-bottom: 6px;
                font-size: 1rem;
                font-family: $header-fontfamily;
            }
        }
    }
}
