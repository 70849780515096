// Custom PlusButton made with Semantic UI

.custom-plus-button.ui.button {
   padding: 0.625rem;
    img {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 0.33rem;
        margin-top: -3px;
    }
}