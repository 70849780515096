@import '../../css/mixins.scss';


.save-modal-loader {
    height: 100vh;
    .ui.dimmer {
        background: rgba(0, 0, 0, 0.4);
    }
}

.modal-save-story-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.modal-save-story {
    color: #fff;
    padding: 1rem 0rem;
    
    &:focus {
        outline: none;
    }
    .ui.text.container {
        font-size: 14px;
        width: 75%;
        @include breakpoint(tablet){
            font-size: 1rem;
        }
        .save-story-buttons {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            text-align: center;
            .ui.button {
                padding: 0.7rem;
            }
            .ui.button:first-child {
                margin-right: 0.5rem;
            }
            @include breakpoint(tablet){
                text-align: right;
                .ui.button:first-child {
                    margin-right: 1rem;
                    margin-bottom: 0;
                }
            }
        }
    }
    .dimmable {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.55);
    }
}

