//Sliding Pane - Custom css settings

@import '../../css/settings.scss';
@import '../../css/mixins.scss';

.slide-pane__overlay {
    z-index: 999;
    .slide-pane__header {
        background-color: #fff;
        border: none;
        height: 40px;
        .slide-pane__close {
            position: absolute;
            right: 0;
            padding: 0 1rem 0 0;
            font-size: 1.3rem;
            opacity: 1;
            .slidingpane-close-btn-wrapper {
                padding: 0 0.25rem;
                display: inline-block;
                .slidingpane-close-btn {
                    opacity: 0.7;
                }
            }
        }
        .slide-pane__title-wrapper {
            padding: 0 0 0 1rem;
            margin: 0;
            .slide-pane__title {
                font-weight: 500;
                font-size: $font-m-l;
                text-transform: uppercase;
                width: calc(100% - 150px);
            }
        }
    }
    .slide-pane__content {
        .ui.input {
            width: 100%
        }
        .button-wrapper {
            text-align: center;
        }
    }
}


//No scroll on body if open...
.ReactModal__Body--open {
    overflow: hidden;
    position: relative;
    touch-action: none;
    -ms-touch-action: none;
}

.slide-pane_from_bottom, .slide-pane_from_right {
    height: calc(100% - 40px);
    @include breakpoint(computer){
        height: 100%;
        margin-top: 0;
        width: 40%!important;
    }
    margin-top: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    @include breakpoint(computer){
        border-top-right-radius: 0px;
    }
    // @include breakpoint(tablet){
    //     width: auto!important;
    // }
    .slide-pane__header {
        border-top-left-radius: 7px;
        border-top-right-radius: 6px;
        .slide-pane__close {
            padding: 0 14px 6px 0;
            @media(min-width: 768px) {
                padding-right: 2rem!important;
            }
        }
    }
}
.answer-slidingpane-overlay.slide-pane__overlay {
    .slide-pane_from_right {
        .slide-pane__header  {
            .slide-pane__close {
                margin-left: 1rem;
                padding: 0;
            }
        }
    }
}

.slide-pane__overlay {

    .slide-pane__header  {
        .slide-pane__title-wrapper {
            @media(min-width: 768px) {
                padding-left: 2rem!important;
            }
        }
    }

    .slide-pane__content {
        padding: 0;
        overflow-x: hidden ;
        @media(min-width: 768px){
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
} 

.slide-pane_from_bottom.content-after-open.hide {
    left: -100%;
}



.slide-pane__overlay.overlay-after-open {
    background: rgba(0, 0, 0, 0.55);
}

.custom-slidingpane-secondary {
    height: calc(100% - 40px);
    margin-top: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    .slide-pane__header {
        border-top-left-radius: 7px;
        border-top-right-radius: 6px;
        .slide-pane__title-wrapper {
            text-align: center;
            .slide-pane__title {
                max-width: 100%;
            }
        }
        .slide-pane__close {
            margin-left: 14px;
            left: 0;
        }
    }
}

.slide-pane__overlay.overlay-after-open {
    @include breakpoint(tablet){
        width: auto!important;
        padding-left: 5rem;
        padding-right: 5rem;
    }
    @include breakpoint(computer){
        padding: 0;
    }
}

.slide-pane__overlay.overlay-before-close {
    transition: background-color 0.5s;
    background: transparent;
}

.slide-pane__overlay.overlay--after-close{
    transition: background-color 0.5s;
    background: transparent;
 }

.custom-slidingpane-secondary {
    transition: 500ms;
    opacity: 0;
    &.open {
        transition: 500ms;
        opacity: 1;
    }
}

//Save button

.slidingpane-action-btn {
    line-height: 2;
    .ui.button {
        padding: 0.5rem 0.5rem;
        margin-right: 0.8rem;
        font-weight: 800;
    }
}