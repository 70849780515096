/* Project.scss */

@import '../../../css/settings.scss';
@import '../../../css/mixins.scss';
.Ija {
    margin-bottom: 1rem;
}
//Elements gallery
.content-gallery-wrapper {
    &.content-gallery-wrapper-placeholder.ui.placeholder {
        height: 160px;
        position: relative;
        overflow: unset;
            .image:not(.header):not(.ui) {
                height: 160px;
                .ui.image {
                    height: 100%;
                    cursor: pointer!important;
                }
            }
    }
    //Show while uploading..
    &.no-description {
        height: 160px;
        .ui.image {
            height: 100%;
            cursor: pointer!important;
        }
    }
    height: 200px;
    width: 50%;
    display: inline-block;
    padding: 0.5rem 0.33rem;
    // z-index: 9999;
    // overflow: hidden;
    position: relative;
    @include breakpoint(computer){
        width: 33%;
    }
    .play {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 5rem;
        height: auto;
    }
    .ui.image {
        @include image-responsive(cover);
        height: calc(100% - 40px);
        cursor: move;
    }
    .content-gallery-delete {
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: rgba(#fff, 0.8);
        border-radius: 50%;
        height: 30px;
        width: 30px;
        text-align: center;
        i.icon {
            text-align: center;
            line-height: 2;
            margin: 0 auto;
            @include breakpoint(computer){
                line-height: 1.7;
            }
        }
    }
}

.ui.dimmer.deleting-loader {
    position: fixed!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
}

.vehicle-page {
    .project-link {
        margin-left: 0.5rem;
        vertical-align: text-bottom;
        @include font-size(small-l);
        .ui.button {
            padding: 0.25rem 0.5rem;
            .icon {
                padding-left: 0.5rem;
            }
        }
    }
}

.latest-update-date {
    line-height: 2;
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
    @include font-size(small-s)
}

.project-info-wrapper {
    &.ui.grid {
        margin-top: 0!important;
        .column:not(.row).project-info-data-container {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            @include breakpoint(tablet){
                padding-left: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem;
            }
        }
    }
    text-align: right;
    .project-info-data-container {
        text-align: center;
        @include breakpoint(tablet){
            text-align: right;
        }
        .icons-social {
            width: 25%;
            display: block;
            float: left;
            @include breakpoint(tablet){
                margin-right: 1rem;
                width: auto;
                display: inline-block;
                float: none;
            }
            .ui.image {
                display: inline-block;
                margin-right: 5px;
            }
            .social-amount {
                font-size: 14px;
            }
        }
    }
}

.vehicle-info {
    text-align: right;
    font-size: 0.875rem;
    color: $grey-dark;
}

.vehicle-data {
    text-align: center;
    .ui.header {
        text-align: center;
        cursor: pointer;
        display: inline-block;
        .vehicle-data-icon {
            display: inline-block;
            height: 14px;
            width: 10px;
            margin-left: 0.5rem;
            cursor: pointer;
            .ui.image {
                @include breakpoint(computer){
                    display: block;
                }
            }
            i.icon {
                color: $dark;
                margin-left: 1rem;
                @include breakpoint(tablet) {
                    font-size: 1.33rem;
                    cursor: pointer;
                }
            }
        }
    }
    .CircularProgressbar {
        height: 42px;
        width: 42px;
        margin-left: 1rem;
        font-weight: 500;

    }
}

.image_container.preview_img {
    margin: 0 auto;
}

//SlidingPane

.sliding-pane-overlay {
    z-index: 99999;
}

.vehicle-panel-info {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .slide-pane__header {
        background: none;
        border: none;
        height: 40px;

        .slide-pane__title-wrapper {
            margin-left: 1em;
            .slide-pane__title {
                font-weight: 700;
            }
        }

        .slide-pane__close {
            position: absolute;
            right: 0;
        }
    }

    .slide-pane__content {
        padding: 0;
    }

    //Form custom label
    .custom_label {
        color: #A0A0A0;
    }
}

.working-hours {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999;
    color: #fff;
    .content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        p {
            font-size: 0.8125rem;
        }
    }
    h1.ui.header {
        color: #fff;
    }
}

.new-element-popup-overlay {
    //Negative margin to get full height of the screen
    margin-top: -10rem;
}

.new-element-popup-content {
    border-radius: 5px;
    .popup-header {
        background-color: rgba($grey-dark, 0.15);
        .ui.header {
            margin-bottom: 0;
        }
    }
    .ui.teal.message {
        margin-top: 1rem;
    }
    .ui.button {
        padding: 0;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0.8rem 0.5rem!important;
        text-align: left;
        .ui.image {
            width: 30px;
            height: 30px;
            display: inline-block;

        }
        span, div {
            color: $dark;
            font-weight: 500;
            font-size: 1rem;
            margin-left: 0.5rem;
        }
        div {
            white-space: pre-line;
            padding-top: 0.1rem;
        }

    }
}
//Parts (Teile)

.part-cost-placeholder {
    .ui.placeholder {
        background-color: $grey-lighter;
        .line {
            background-color: $grey-lighter;
        }
    }
}

.parts {
    h2.ui.header {
        margin-bottom: 0;
        line-height: 2;
    }
    .ui.header {
        span {
            color: rgba($dark, 0.5);
            font-size: $font-s-l;
            font-family: $page-fontfamily;
        }
    }
    .parts-item {
        cursor: pointer;
    }
    .description {
        font-size: $font-s-l;
    }
    .buyed-date {
        font-size: $font-s-l;
        color: rgba($dark, 0.5);
    }
    .ui.tiny.images {
        margin-top: 0.5rem;
        .image {
            height: 60px;
            width: 80px;
            object-fit: cover;
        }
    }
}

.parts-gallery-preview-wrapper {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.parts-gallery-preview.ui.grid {
    .column:not(.row){
        padding: 0.25rem;
    }
    .play {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 3rem;
        height: auto;
    }
    .more-images {
        display: inline-block;
        font-size: 1.75rem;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba($grey-dark, 0.2);
        vertical-align: middle;
        position: relative;
        color: rgba($dark, 0.15);
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            font-weight: 500;
        }

        &.documents {
            img {
                opacity: 0.25;
            }

            span {
                color: rgba($dark, 1);
            }
        }
    }
}

.parts-gallery-inside.ui.grid {
    @include breakpoint(computer){
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .column:not(.row){
        padding: 0.25rem;
        @include breakpoint(computer){
            height: 200px;
            .ui.image {
                @include image-responsive(cover)
            }
        }
    }
}

//Costs (Ausgaben)
.costs {
    h2.ui.header {
        line-height: 2;
    }
    .total-costs {
        text-align: center;
        .total {
            font-size: 3rem;
            line-height: 3rem;
            @include breakpoint(tablet){
                font-size: 3.75rem;
                line-height: 3.75rem;
            }
        }
        .eur {
            @include font-size(medium-s);
            font-weight: 600;
            @include breakpoint(computer){
                margin-top: 0.25rem;
            }
        }
    }
    .costs-item {
        cursor: pointer;

        .describtion {
            margin-top: -5px;

            &.grey {
                color: rgba($dark, 0.5);
            }
        }

        .bill-icon {
            width: 30px;
            margin-left: auto;
            opacity: 0.1;

            &.has-bill {
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

.ui.segment {
    background-color: $grey-lighter;
    box-shadow: none;
    .ui.header {
        text-transform: none;
        margin-bottom: 0;
    }
    .describtion {
        @include font-size(small-s);
    }
    .amount {
        @include font-size(small-m);
        text-align: right;
        font-weight: bold;
        white-space: nowrap;
    }
    .date {
        @include font-size(small-s);
        color: rgba($dark, 0.5);
    }
}

//Bills (Rechnungen)
.bills {
    h2.ui.header {
        line-height: 2;
    }
    .bill-item {
        cursor: pointer;
        .files-quantity {
            color: rgba($dark, 0.5);

            &.has-items:hover {
                color: rgba($dark, 1);

                .ui.image {
                    opacity: 1;
                }
            }

            .quantity {
                font-size: 30px;
                margin-right: 0.5rem;
                display: inline-block;
            }
            .ui.image {
                margin-top: -1rem;
                opacity: 0.6;
            }
        }

        .describtion {
            margin-top: -5px;
            color: rgba($dark, 0.5);
        }

        .date {
            color: rgba($dark, 1);
        }

        .bill-expenses {
            @include font-size(small-m);
            color: rgba($dark, 0.5);
            clear: both;

            &:hover {
                color: rgba($dark, 1);
            }

            @include breakpoint(tablet){
                text-align: right;
            }

            span {
                float: right;
                text-align: right;
                min-width: 130px;

                @include breakpoint(computer){
                    margin-right: 64px;
                }
            }
        }
    }
}

//expenditure

.time {
    font-size: 3.75rem;
    line-height: 3.75rem;
}

.hours {
    font-weight: 500;
}

.not-assigned {
    color: $grey-dark;
    font-size: 0.76rem;
    font-weight: 600;
}

.expenditure-item {
    cursor: pointer;
    .expenditure-date {
        @include font-size(small-l);
    }
    .expenditure-amount {
        font-weight: 600;
        @include font-size(small-l)
    }
}

.expenditure-content {
    .expenditure-label-gallery {
        margin-top: 1rem;
        margin-left: -0.75rem;
        .ui.label {
            cursor: pointer;
        }
    }
    .expenditure-label {
        margin-left: -0.75rem;
        .ui.label {
            cursor: pointer;
        }
    }
}

//settings.js

.ui.toggle.checkbox.settings-checkbox-toggle {
    label {
        //Checkbox footer navigation fix
        &:after, &:before {
            z-index: 0
        }
    }
}

.social-buttons-wrapper {
    position: relative;
}

.social-buttons.project-detail {
    background: $grey-lighter;
    .ui.header.vehicle-data {
        margin-top: 0;   
    }
    @include breakpoint(tablet){
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        color: $grey-dark;
        text-align: right;
    }
}

.story-content-placeholder.ui.placeholder {
    max-width: 100%;
    margin-top: 1rem;
    .image:not(.header):not(.ui) {
        height: 300px;
        max-width: 100%;
    }

}

.more-stories {
    background-color: $grey-dark;
    width: 100%;
}

.user-storys {
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: hidden;
    &.placeholder {
        @include breakpoint(computer){
            .ui.placeholder {
                max-width: 100%;
                height: 350px;
            }
        }
    }
   .project-storys-wrapper {
       padding: 0 1rem 1rem 1rem;
   }
   .ui.placeholder {
    .image:not(.header):not(.ui){
           height: 300px;
       }
   }
}

.project-storys-innerwrapper {
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 10px -2px rgba(0,0,0,1);
    box-shadow: 0px 0px 10px -5px rgba(0,0,0,1);
    border-radius: $border-radius;
    overflow: hidden;
}

//Position fix - footer
.slick-prev, .slick-next {
    position: relative!important;
    padding: 1rem 0;
}

.story-description {
    @include font-size(small-l);
    margin-bottom: 0.5rem;
}

.stories-text-images {
    clear: both;
}

.text-image {
    position: relative;
    margin-bottom: 1rem;
    .image-description {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 0.75rem;
        padding: 0.33rem 0.5rem;
    }
}

.more-stories {
    background-color: rgba($grey-dark, 0.2);
    .project-storys-wrapper {
        margin-top: 0.5rem;
    }
}


//List-projects
.filter-labels {
    padding: 0.5rem 0;
    @include breakpoint(tablet){
        padding: 1rem;
    }
    a.ui.label {
        @include breakpoint(tablet){
            font-size: $font-s-l;
        }
        .icon {
            position: static;
        }
    }
}

.projects-placeholder.ui.placeholder {
    max-width: 100%;
    height: 480px;
    .image {
        // width: 100%;
        .header.image {
            height: 68px;
            background: rgba(0, 0, 0, 0.5);
            .line {
                margin-top: 1rem;
            }
            &:after {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                margin: 0.75rem 0.8rem;
            }
        }
    }
}

.projects-list {
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;
    @include box-shadow();
    .user-header {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0.75rem 0.8rem;
        color: #fff;
    }
    .user-image-circular {
        float: left;
        margin-right: 0.5rem;
    }
    .user-info {
        line-height: 1;
        .project-name {
            font-family: $header-fontfamily;
            font-size: $projects-mobile-fontsize;
            @include breakpoint(tablet){
                font-size: $projects-desktop-fontsize;
            }
            font-weight: 500;
        }
        .user-name {
            @include font-size(small-m);
            font-weight: 600;
        }
    }
    .project-images {
        .top-image {
            height: 200px;
            @include breakpoint(tablet){
                height: 320px;
            }
            .ui.image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .first-image {
            width: calc(50% - 1px);
            height: 240px !important;
            display: inline-block;
            margin-right: 2px;
            @include breakpoint(tablet){
                height: 400px;
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .secondary-image {
            width: calc(50% - 1px);
            height: 240px !important;
            display: inline-block;
        }
        .bottom-images {
            .first, .second {
                height: 140px;
                @include breakpoint(tablet){
                    height: 250px;
                }
            }
            .first {
                width: calc(50% - 1px);
                display: inline-block;
                margin: 2px 1px 0 0px;
                .ui.image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .second {
                width: calc(50% - 1px);
                display: inline-block;
                margin: 2px 0px 0 1px;
                position: relative;
                .overlay {
                    position: absolute;
                    z-index: 1;
                    text-align: center;
                }
                .ui.image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .project-social-buttons {
        padding: 0.5rem 0;
        @include font-size(small-m);
        .ui.grid {
            margin-left: 0;
            margin-right: 0;
            @include breakpoint(tablet){
                margin-right: -1rem;
                margin-left: -1rem;
            }
        }
        .column {
            text-align: center;
        }
    }
}

//Dropdown dots menu - desktop
.elements-dots-menu {
    .elements-options-menu {
        .ui.dropdown .menu > .item:hover {
            background: unset;
        }
        .ui.dropdown {
            line-height: 0;
            .visible-story {
                background-color: rgba($dark, 0.2)!important;
                &.publish {
                    background-color: $green!important;
                }
                &.item {
                    color: #fff;
                }
            }
            .delete-story {
                background-color: $red-light!important;
                &.item {
                    color: #fff;
                }
            }
            .purchase-part {
                background-color: $green!important;
                &.item {
                    color: #fff;
                }
            }
            .sort-btn.up {
                background-color: rgba(54, 34, 15, .2);
                &.item {
                    background-color: rgba(54, 34, 15, .2);
                }
            }

            .sort-btn.down {
                background-color: rgba(54, 34, 15, .5);
                &.item {
                    background-color: rgba(54, 34, 15, .5);
                }
            }
            .menu > .item > .image {
                width: 30px;
                height: 30px;
            }
        }
        .elements-options-menu-dots.ui.image {
            width: 20px;
            height: 20px;
        }

        .elements-options-menu-icon.ui.image {
            width: 30px;
            height: 30px;
            float: left;
        }
    }
}

.editable-list-item {
    cursor: pointer;

    .date-urgent {
        color: $red-light;
    }
    .date-done {
        color: $green;
    }
    .date-none {
        opacity: 0.5;
    }

    .sub-title {
        margin-top: -2px;
        display: block;
        font-size: 0.75rem;
        color: rgba($dark, 0.5);
        &.category {
            margin-top: -4px;
            color: rgba($dark, 1);
        }
    }
}

//Swipe buttons
.rc-swipeout-btn {
    &.sort-btn.up {
        background-color: rgba(54, 34, 15, .2);
    }

    &.sort-btn.down {
        background-color: rgba(54, 34, 15, .5);
    }
}








