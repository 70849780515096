@import '../../css/settings.scss';
@import '../../css/mixins.scss';
/*
    Layout.scss
*/

body {
    #root {
        overflow-x: hidden;
        min-height: 100%;
        position: relative;
        padding-bottom: calc(198px + env(safe-area-inset-bottom)); //Mobile footer menu + footer height, safe-area iOS homebar issue fix 
        @include breakpoint(tablet){
            padding-bottom: 180px; //Mobile footer menu hidden
        }
    }
    &.dashboard {
        #root {
            padding-bottom: 58px; // Mobile footer menu height
            // overflow-x: hidden;
            @include breakpoint(tablet){
                padding-bottom: 0 // Mobile footer menu hidden
            }
        }
    }
    //Active Add to home screen banner 
    &.aths-open {
        .ui.menu.secondary.footer-mobile-menu {
            bottom: 55px!important;
            //Set to default 
            height: 58px;
            padding-bottom: 0;
        }
        #ConsentBanner {
            bottom: 55px!important;
            @include breakpoint(tablet){
                bottom: 0!important;
            }
        }
    }
}

.pushable:not(body) {
    transform: none;
}

#root .pushable > .pusher.dimmed {
    z-index: 102;
    padding-bottom: 100px; // A bit hacky ;)
}

#root .pusher {
    min-height: calc(100vh - 64px);
}

#root {
    .pushable {
        // @include breakpoint(tablet){
        //     overflow: visible;
        // }
    }
}

.top-header.ui.inverted.segment {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

//Top header - backend
.top-header.ui.inverted.segment, .backend {
    background: rgb(231,52,76);
    background: linear-gradient(90deg, rgba(231,52,76,1) 35%, rgba(172,28,47,1) 100%);
    @include breakpoint(tablet) {
        margin-right: 0;
        margin-left: 0;
    }
    .logo-frontend {
        width: 100px;
        @include breakpoint(tablet) {
            width: 130px;
        }
    }
    span {
        color: #fff;
        font-size: 1rem;
        font-family: $header-fontfamily;
        font-weight: 500;
    }
    .top-header-icon {
        width: 32px;
        height: 32px;
        color: #fff;
    }
    .top-header-user.item {
        width: 130px;
        a.logo.item {
            &:hover {
                background: none;
            }
        }
        &.active {
            background: none!important;
            .ui.image {
                border: 2px solid #fff;
            }
        }
        .login-item {
            margin-left: auto;
            cursor: pointer;
        }
        &.active .login-item {
            .ui.image {
                border: none;
            }
        }
        padding-left: 0;
        .ui.image {
            width: 40px;
            height: 40px;
        }
    }
    .notifications {
        height: 26px;
        width: 26px;
        background-color: $red-light;
        border-radius: 100%;
        margin-right: 0.5rem;
        margin-left: auto;
        .notifications-amount {
            line-height: 1.5;
        }
    }
}

//Top header - submenu
.ui.secondary.menu.sub-menu {
    @include breakpoint(tablet){
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }
}

.ui.secondary.menu.pointing {

    .item {
        i {
            z-index: 999;
        }
    }

    .go-back-icon {
        width: 24px;
        height: 24px;
        .ui.image {
            width: 100%;
            height: 100%
        }
    }

    .top-header-title {

        h1{
            color: #fff;
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1;
            font-size: $header-fontsize;
        }
    }
    //Search
    .search {
        position: absolute;
        right: 0;
        top: 0;
        i {
            padding: 0.5em 0;
        }
    }
}

//Mobile nav


// .ui.menu.fixed {
//     z-index: 1;
// }

//Medium Device - Menu
.medium-topheader-menu.ui.menu.secondary.inverted, .medium-topheader-menu.ui.menu.fixed, .ui.mini.top-header {
    &.frontend {
        background: rgb(231,52,76);
        background: linear-gradient(90deg, rgba(54, 34, 15, 1) 35%, rgba(31, 19, 8, 1) 100%);
        .logo-frontend {
            width: 100px;
            @media(min-width: "768px") {
                width: 130px;
            }
        }
    }
    &.backend {
        background: rgb(231,52,76);
        background: linear-gradient(90deg, rgba(231,52,76,1) 35%, rgba(172,28,47,1) 100%);
        @media(min-width: "768px") {
            margin-right: 0;
            margin-left: 0;
        }
    }
    @media(min-width: "768px"){
        margin-right: 0;
        margin-left: 0;
    }
    .menu-item {
        &.item {
            margin-right: 0.5rem;
            border-left: 1px solid rgba(#fff, 0.5);
            border-radius: 0;
            padding-top: 2px;
            padding-bottom: 2px;
            margin-right: 0;
            &:first-child {
                border: none;
                margin-right: 0;
            }
            &:nth-child(4) {
                border-right: 1px solid rgba(#fff, 0.5);
            }
            &:last-child {
                border: none;
            }
            .ui.image {
                width: 32px;
                height: 32px;
            }
            &.active, &:first-child.active, &:hover {
                background: none!important;
                span {
                    border-bottom: 2px solid $red-light;
                    margin-top: 2px;
                    display: block;
                }
            }
        }
    }
    .menu-user-login {
        width: 130px;
    }
}

.ui.menu.secondary.footer-mobile-menu {
    //Fix homebar iOS issue 
    height: calc(58px + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    transition: height 300ms ease;
    background-color: rgba(#F9F9F9, 1);
    position: sticky;
    position: fixed;
    //Dropdown menu
    //Footer Icons
    .item {
        justify-content: center;
        .footer-icon {
            svg {
                width: 32px;
                height: 32px;
            }
        }
        &.active {
            .footer-icon {
                svg {
                    path {
                        fill: $red-light;
                    }
                }
            }
        }
    }
    .footer-fullsize-menu {
        .ui.vertical.menu.fluid {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            margin-top: 48px;
            height: calc(100% - 48px - 55px);
            width: 100%;
            overflow: auto;
            z-index: 999999;
        }
     
        .item {
            width: 100%;
            text-align: left;
            margin: 0;
            padding: 1rem;
            border-bottom: 1px solid rgba($dark, 0.1); 
            &.active:hover {
                background: transparent;
            }
        }
    }
    .item {
        width: calc(20% - 15px);
        margin-left: 7.5px;
        margin-right: 7.5px;
        padding-left: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0;
        vertical-align: middle;
        align-items: center;
        border-radius: 0px;
        img {
            margin: 0 auto;
            margin-top: 4px;
        }
    }
    a.item:hover, a.item:active, .active.item {
        border-top: 2px solid $red-light;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background: none;
        margin-top: -2px;
    }
    div.item:active {
        background: none;
    }
    a.item.login-footer{
        &:hover, &:active, &.active {
            border: none;
            margin-top: 0;
        }
    }
    a.item:last-child {
        &:hover {
            border: none;
            margin-top: 0;
        }
    }
    .user-footer.item {
        justify-content: center;

    }
    .user-footer.active.item{
        border-top: none;
        margin-top: 0;
        .user-img {
            border: 2px solid $red-light;
        }
    }
    .user-footer-wrapper, a.item.login-footer {
        position: relative;
        .user-img {
            border-radius: 100%;
            height: 48px;
            width: 48px;
            position: relative;
            margin: 0 auto;
            border: 2px solid #fff;
            object-fit: cover;
        }

        .notification {
            background-color:  $red-light;
            color: #fff;
            height: 25px;
            width: 25px;
            position: absolute;
            top: -5px;
            right: -5px;
            border-radius: 100%;
            span {
                font-size: 12px;
                line-height: 2;
            }
        }
    }

}

.footer-icon {
    width: 32px;
    height: 32px;
}

//Project navigation
.project-nav-loading {
    opacity: 0.6;
}
.ui.menu.secondary.pointing.project-nav,
.project-nav.ui.menu.secondary{
    font-size: 0.8125rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    .item {
        padding-left: 0.8em;
        padding-right: 0.8em;
    }
    @media(min-width: 768px){
        border-top: 2px solid rgba(34, 36, 38, 0.15);
        justify-content: space-evenly;
    }
    .project-nav-dropdown.item {
        padding-top: 0;
        .ui.dropdown {
            .menu {
                right: -10px;
                left: auto;
                top: 38px;

            }

        }
    }
}

//Acount Settings - Menu item
.account-settings {
    .user-profil-img {
        line-height: 90px;
        img {
            width: 90px;
            height: 90px;
            float: left;
        }
        .user-profil-edit {
            margin-left: 2rem;
            display: inline-block;
        }
    }
    .ui.header {
        text-transform: none;
    }
    .new-password{
        &.ui.grid > .column:not(.row) {
            padding-bottom: 0.5rem;
        }
    }
    .user-info {
        .ui.checkbox {
            float: right;
        }
        .ui.toggle.checkbox input, .ui.fitted.checkbox label {
            margin-top: 50%;
        }
    }
    .delete-account {
        text-align: center;
        a {
            color: $red-light;
            font-weight: 500;
            font-size: 0.875rem;
        }
    }
}

//Your Projects
.custom-item-panel {
    .item-img {
        position: relative;
        .ui.label {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0;
            border-radius: 0;
        }
    }
    .item-info {
        .item-info-content {
            margin: 0.25rem 0!important;
        }
        .ui.image {
            width: 18px;
            height: 18px;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

main {
    padding-bottom: 1rem;
}

//Page footer
footer {
    @include breakpoint(tablet){
        height: 180px;
    }
    position: absolute;
    height: 140px;
    bottom: calc(58px + env(safe-area-inset-bottom));
    @include breakpoint(tablet){
        bottom: 0;
    }
    left: 0;
    right: 0;
    width: 100%;
    background: $grey-light;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    .vetero-logo-footer {
        .ui.image {
            width: 154px;
            height: 50px;
        }
    }
    .partners-header, .logo-description {
        font-size: $font-s-l;
    }
    .ui.grid .partners-header.column:not(.row) {
        padding-top: 0;
        @include breakpoint(tablet){
            padding-top: 1rem;
        }
    }
    .logo-description {
        font-weight: 600;
    }
    .footer-menu {
        .ui.menu.secondary.vertical > .item {
            padding: 0.25rem 0;
            padding-left: 0.25rem;
            font-size: $font-s-l;
            &:hover {
                background: none;
            }
        }
    }
    .partners {
        .rektol-logo {
            .ui.image {
                width: 100px;
                height: 70px;
                margin: 0 auto;
            }
        }
        .partner-text {
            margin-top: 0.5rem;
            display: block;
            color: rgba($dark, 0.6);
            font-size: $font-s-l;
            @include breakpoint(tablet){
                line-height: 0.75;
            }
        }
    }
    .meta-nav.ui.menu.text {
        margin: 0 auto;
        justify-content: center;
        @include breakpoint(tablet){
            justify-content: start;
        }
        .item {
            border-right: 1px solid $grey-dark;
            font-size: $font-s-l;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: 0;
            }
        }
    }
}
