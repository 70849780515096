@import "../settings.scss";


h1,.h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: $header-fontfamily;
    font-weight: 500;
    color: $dark;
    // text-transform: uppercase;
}

h1, .h1 {
    font-size: $h1-fontsize;
}

h2, .h2 {
    font-size: $h2-fontsize;
}

h3, .h3 {
    font-size: $h3-fontsize;
}

h4, .h4 {
    font-size: $h4-fontsize;
}