/* Forum.scss */
@import '../../css/settings.scss';
@import '../../css/mixins.scss';

/* ************************************** */
/*
    List of components
    1. Post
    2. threadList
    3. forumTags
    
*/
/* ************************************** */

/* ************************************** */
/*
    1. Post
*/
/* ************************************** */

.post {
    padding-bottom: 1rem;
    .post-author-image {
        width: 54px;
        height: 54px;
        margin-right: 1rem;
        cursor: pointer;
        @include breakpoint(tablet){
            width: 70px;
            height: 70px;
        }
    }
    .post-header {
        display: flex;
        align-items: center;
        line-height: 1;
        .post-meta {
            flex-basis: calc(100% - 54px);
            @include breakpoint(tablet){
                flex-basis: calc(100% - 70px);
            }
            .post-published-date {
                margin-bottom: 4px;
                color: $grey-dark;
                font-size: $font-s-l;
                font-weight: 700;
                span {
                    font-weight: normal;
                    
                }
            }
            .author {
                font-weight: 600;
                font-family: $header-fontfamily;
                font-size: $font-m-l;
            }
            .breadcrumb {
                .ui.breadcrumb {
                    .section a, .section {
                        color: $dark;
                        font-size: $breadcrumb-fontsize;
                        font-weight: 600;
                    }
                }
            }
        }
        .post-edit {
            align-self: flex-start;
            margin-left: auto;
            cursor: pointer;
            padding: 0.5rem;
            margin-right: -0.5rem;
        }
    }
    .post-content {
        margin-top: 1rem;
        margin-left: 0.25rem;
        @include breakpoint(tablet){
            margin-left: 86px;
        }
        blockquote {
            padding: 0 0.875rem;
            margin: 2rem 0;
            border-left: 2px solid $grey-dark;
            font-style: italic;
        }
    }
    .post-content-medias {
        margin-top: 2rem;
        margin-left: 0.25rem;
        @include breakpoint(tablet){
            margin-left: 86px;
        }
    }
    .post-actions {
        font-weight: 600;
        font-size: $font-m-s;
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        &>a {
            margin-left: 1rem;
            cursor: pointer;
        }
        .post-quote {
            .ui.image{
                display: inline-block;
                margin-right: 0.5rem;
                width: 20px;
            }
        }
    }
    &.fetching {
        .post-header {
            .post-author-image {
                max-width: 100%;
                .ui.placeholder {
                    border-radius: 100%;
                    .image:not(.header):not(.ui) {
                        height: 70px;
                        width: 70px;
                    }
                }
            }
            .post-meta {
                width: 100%;
            }
        }
    }
}

/* ************************************** */
/*
    2. threadList
*/
/* ************************************** */
.thread {
    .thread-content {
        display: flex;
        justify-content: space-between;
        .author-avatar {
            width: 44px;
            height: 44px;
            @include breakpoint(tablet){
                width: 50px;
                height: 50px;
            }
        }
        .thread-title {
            margin-right: auto;
            margin-left: 1rem;
            flex-basis: 60%;
            @include breakpoint(tablet){
                flex-basis: 70%;
            }
            h2 {
                margin-bottom: 0;
                font-size: 22px;
                @include breakpoint(tablet){
                    font-size: 24px;
                }
            }
            .thread-meta {
                color: $grey-dark;
                font-size: $font-s-l;
                font-weight: 500;
                .user {
                    display: block;
                    @include breakpoint(tablet){
                        display: inline-block;
                    }
                }
            }
        }
        .thread-info {
            flex-basis: 15%;
            margin-left: auto;
            font-size: $font-s-l;
            .thread-posts-count {
                font-weight: 600;
                img {
                    width: 22px;
                    height: 22px;
                    display: inline-block;
                    margin-right: 0.5rem;
                }
            }
        }
    }
    &.fetching {
        .thread-content {
            justify-content: flex-start
        }
        .author-avatar {
            .ui.placeholder {
                border-radius: 50%;
                .image:not(.header):not(.ui) {
                    height: 50px;
                    border-radius: 100%;
                }
            } 
        }
        .thread-title {
            width: calc(100% - 50px);
            margin: 0;
            .ui.placeholder {
                max-width: 100%;
                margin-left: 1rem;
            }
        }
    }
}

/* ************************************** */
/*
    1. forumTags
*/
/* ************************************** */
.forum-tags {
    .forum-tags-action {
        text-align: center;
        margin-top: 1rem;
        @include breakpoint(tablet){
            text-align: right;
            margin-top: 0;
        }
        .ui.button {
            width: 100%;
            margin-bottom: 0.5rem;
            @include breakpoint(tablet){
                width: auto;
                margin-bottom: 0;
            }
        }
    }

    .choosed-tags .ui.header, .available-tags .ui.header {
        text-transform: unset;
    }
    
    .choosed-tags {
        margin-top: 3.625rem;
    }

    .available-tags {
        .ui.label.disabled {
            cursor: not-allowed;
        }
    }

    .ui.label {
        margin-right: 0.5rem;
        margin-left: 0;
        margin-bottom: 0.5rem;
    }
}