@import '../../../css/mixins.scss';
@import '../../../css/settings.scss';


/* listitem-own-project.js */

//Project titel margin (Progress bar)
.ui.progress {
    margin: 1em 0 1em!important;
}

.your-projects-wrapper {
    .stories-btn {
        margin: 0.5rem 0;
        color: #fff;
        .ui.button.custom-plus-button {
            padding: 4px;
            font-size: 0.688rem;
        }
        &.new {
            .ui.button.custom-plus-button {
                background: $grey-dark;
                color: #fff;
            }
        }
        &.first {
            .ui.button.custom-plus-button {
                background: $dark;
                color: #fff;
            }
        }
    }
}

.own-project-wrapper {
    padding-bottom: 100%;
    margin-bottom: 0.5em;
    position: relative;
    @include breakpoint(tablet){
        padding-bottom: 66.53%;
    }
    .own-project-wrapper-user {
        position: absolute;
        width: 40px;
        padding-bottom: 100%;
        @include breakpoint(tablet){
            top: 0.5rem;
            left: 0.5rem;
        }
        .ui.image {
            border: 2px solid #fff;
            @include image-responsive(cover)
        }
    }
    .ui.image.project-img {
        margin: 0 auto;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include breakpoint(tablet){
            height: 100%;
        }
    }
    //Placeholder
    .ui.placeholder.slides-placeholder {
        width: 100%;
        padding-bottom: 75%;
        .image {
            position: absolute;
            border-radius: 100%;
            height: 100%;
            box-sizing: border-box;
            @media(min-width: "767px"){
                height: 100%;
            }
        }
    }
    .parked-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        background-color: rgba($red-light, 0.40);
        @media(min-width: "767px"){
            border-radius: 0;
        }
        overflow: hidden;
        .ui.red.label{
            position: absolute;
            top: 35%;
            transform: translateY(-35%);
            left: 0;
            right: 0;
            width: 100%;
            padding-right: 10px;
            transform: rotate(-25deg);
            @media(min-width: "767px"){
                width: 120%;
                top: 40%;
                left: -20px;
                font-size: 1rem;
            }
            @media(min-width: "992px"){
                transform: rotate(-25deg);
                padding-right: 0;
                font-size: 1rem;
            }
        }
    }
    .ui.rail {
        width: auto;
        @media(min-width: "768px"){
            &.left.internal {
                left: 10px;
            }
            top: 10px;
        }
        .ui.image {
            border: 2px solid #fff;
        }
    }
}
.update {
    .ui.label.mini {
        padding-left: 5px;
        padding-right: 5px;
    }
    @media(min-width: "768px") {
        position: absolute;
        top: -5px;
        right: 24px;
    }
}

.project-title {
    font-family: 'Barlow', sans-serif;
    @include font-size(small-m);
    margin-top: 0.5rem;
    color: $dark;
}

