/* Pages.scss */
@import '../css/mixins.scss';
@import '../css/settings.scss';

/* ************************************** */
/*
    Pages
    1. Homepage (homepage.js)
    2. Newsfeed (newsfeed.js)
        2a. Newsfeed Input (newsfeedInput.js)
    3. Fe Project (fe-project.js - Frontend Project - Detail Page)
    4. Fe Story (fe-story.js Frontend Story - Detail Page) 
    5. BrandIndex - brandIndex.js
    5. User page (user.js) 
    6. 404 - Page not found (404.js)
    7. Imprint (imprint.js)
    8. Partner (partner.js)
    9. Activation (activation.js)
    10.Password recovery
    11.About / Privacy (about.js + privacy.js) NOTE: Similar stylesheet's
    12. Blog (postlist.js + singlepost.js)
    13. Forum 
    14. Professionals
*/
/* ************************************** */


//Semantic UI Divider custom settings...
.ui.divider.custom-divider {
    border: 0.5px solid #A0A0A0;
    margin-left: 14px;
    margin-right: 14px;
}

// ************************************** 
//
//          1. Homepage                
//
// ************************************** 

.homepage {
    overflow: hidden;
    //Negative margin
    @include breakpoint(tablet){
        margin-bottom: -1rem;
    }
    .ui.header {
        font-family: 'Teko', sans-serif;
    }
    h1.ui.header {
        font-size: 3.125rem;
        letter-spacing: 1.5px;
        // margin-bottom: 0;
        line-height: 1;
        @include breakpoint(tablet){
            font-size: 4.688rem;
        }
    }
    h2.ui.header {
        font-size: 2.5rem;
        @include breakpoint(tablet){
            font-size: 3.75rem;
        }
    }

}

.homepage-header {
    background-image: url(../images/homepage/home-head-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(100vh - 48px - 58px);
    width: 100%;
    position: relative;
    @include breakpoint(tablet){
        height: 70vh;
    }
    @include breakpoint(computer){
        height: 80vh;
        background-position: center center;
    }
    //Overlay
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(#150D06, 0.45);
        height: 100%;
        width: 100%;
    }
    .homepage-header-content {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
        .ui.header {
            font-size: 3.125rem;
            letter-spacing: 1.5px;
            // margin-bottom: 0;
            line-height: 1;
            @include breakpoint(computer){
                font-size: 4.688rem;
            }
        }
        p {
            font-size: 1.125rem;
            margin-bottom: 64px;
            @include breakpoint(computer){
                font-size: 1.75rem;
            }
        }
        .ui.header, p {
            color: #fff;
        }
    }
}

.homepage {
    .flex-gallery {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            bottom: 44px;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 1) bottom;
            filter: blur(36px);
            -webkit-filter: blur(36px);
            height: 60px;
            width: 100%;
            @include breakpoint(computer) {
                height: 100px;
                bottom: 23px;
            }
        }
        .flex-gallery-items:last-child {
            @include breakpoint(computer){
                height: 180px;
                overflow: hidden;
            }
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 1);
            height: 50px;
        }
    }

    .banner-register {
        background-image: url(../images/homepage/home-cta-1.jpg);
        background-size: cover;
        background-position: center;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            background: rgba($red-light, 0.85);
            height: 100%;
            width: 100%;
        }
        .banner-content {
            z-index: 9;
            color: #fff;
            padding-top: 32px;
            padding-bottom: 46px;
            @include breakpoint(tablet){
                padding-bottom: 66px;
                padding-top: 86px;
            }
            .ui.header {
                color: #fff;
                line-height: 1;
            }
            p {
                font-size: 0.935rem;
                margin-bottom: 35px;
                @include breakpoint(tablet){
                    font-size: 1.375rem;
                }
            }
            .ui.button {
                background-color: #fff;
                color: $red-light;
                width: 100%;
                @include breakpoint(tablet){
                    width: unset;
                }
            }
        }
    }

    .list-section {
        padding-top: 32px;
        padding-bottom: 0;
        @include breakpoint(tablet){
            padding-top: 71px;
            padding-bottom: 0;

        }
    }

    .list-benefits {
        @include breakpoint(tablet){
            background: url(../images//homepage/home-about-vetero.jpg);
            background-position: right center;
            background-size: cover;
            background-repeat: no-repeat;
            padding-top: 22px;
            padding-bottom: 22px;
        }
        @include breakpoint(largeMonitor){
            background-size: contain
        }
        .ui.list {
            .item {
                display: flex;
                @include breakpoint(tablet){
                    width: 50%;
                }
                .list-custom-icon {
                    margin-right: 20px;
                    padding: 19px;
                    width: 64px;
                    height: 64px;
                    background-color: rgba($dark, 0.15);
                    border-radius: 100%;
                    margin-bottom: 41px;
                    .ui.image {
                        height: 100%;
                        width: 100%;
                    }
                    @include breakpoint(tablet){
                        width: 70px;
                        height: 70px;
                        padding: 15px;
                    }
                }
                .content {
                    width: calc(100% - 64px);
                    font-size: 0.813rem;
                    margin-top: 5px;
                    @include breakpoint(tablet){
                        width: calc(100% - 70px);
                        font-size: 1rem;
                    }
                    .header {
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-bottom: 6px;
                        font-size: 1.25rem;
                        @include breakpoint(tablet){
                            font-size: 1.375rem;
                        }
                    }
                }
            }
        }
    }
}

// ************************************** 
//
//          2. Newsfeed                
//
// ************************************** 

.ui.segment.newsfeed-content {
    background-color: #fff;
    border-radius: $border-radius;
    @include box-shadow();
    padding-bottom: 0;
    &.newsfeed-content-placeholder {
        margin-bottom: 2rem;
    }
    .newsfeed-label {
        display: inline-block
    }
    .newsfeed-inside {
        .meta-info {
            @include font-size(small-m);
            color: $grey-dark;
            font-weight: 600;
            margin-bottom: 0.5rem;
            margin-top: 0.25rem;
            time {
                margin-right: 0.5rem;
            }
        }
        .edit-feedpost {
            position: absolute;
            right: 0.5rem;
            top: 1rem;
        }
    }
    .newsfeed- {
        .newsfeed-content-userimg-wrapper {
            width: 40px;
            height: 40px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .preview-images {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: -1rem;
        height: 130px;
        @include breakpoint(tablet){
            height: 240px;
        }
        .first, .second {
            height: inherit;
        }
        .gallery {
            height: inherit;
            .first {
                width:70%;
                display: inline-block;
            }
            .second {
                width: 30%;
                display: inline-block;
                position: relative;
                border-left: 1px solid #fff;
            }
        }
    }
    .meta-date {
        font-size: 0.688rem;
        color: $grey-dark;
        font-weight: 600;
    }
    .project-name {
        .project-title {
            @include font-size(medium-s);
            // font-family: $header-fontfamily;
            color: $grey-dark;
            font-weight: 600;
            display: block;
            margin-top: 1rem;
        }
    }
    .content-title {
        @include font-size(large-m);
        font-weight: 500;
        font-family: $header-fontfamily;
        margin-bottom: 5px;
        display: block;
    }
    .newsfeed-title {
        margin-bottom: 13px;
    }
    .newsfeed-description {
        @include font-size(small-l);
        color: $dark;
        line-height: 1.5;
        margin-bottom: 1rem;
        .ui.placeholder {
            max-width: 100%;
        }
    }
    .newsfeed-image, .newsfeed-images {
        margin-left: -1rem;
        margin-right: -1rem;
        height: 160px;
        cursor: pointer;
        @include breakpoint(tablet){
            height: 300px;
        }
        @include breakpoint(computer){
            height: 350px;
        }
    }
    .newsfeed-image {
        .feed-image {
            height: inherit;
            .ui.image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .ui.placeholder {
            max-width: 100%;
            margin-top: -1rem;
            .image {
                height: 200px;
                @include breakpoint(tablet) {
                    height: 400px
                }
            }
        }
    }
    .newsfeed-images {
        .feed-images {
            width: 100%;
            .ui.image {
                width: 100%;
                height: 100%;
            }
            .first, .second {
                height: 160px;
                cursor: pointer;
                @include breakpoint(tablet){
                    height: 300px;
                }
                @include breakpoint(computer){
                    height: 350px;
                }
            }
            .first {
                width: 70%;
                display: inline-block;
            }
            .second {
                width: 30%;
                display: inline-block;
                position: relative;
                border-left: 1px solid #fff;
            }
        }
    }
    .social-buttons.ui.grid {
        @include breakpoint(tablet){
            margin-bottom: 0;
        }
        .icons-newsfeed {
            line-height: 1;
        }
        span {
            font-weight: 500;
        }
        .social-amount {
            font-weight: 500;
            // margin-right: 0.5rem;
        }
        .icons-newsfeed {
            cursor: pointer;
            margin-right: 1.5rem;
            display: inline-block;
            img {
                margin-right: 0.25rem;
            }
        }
        .icon-newsfeed-social {
            display: inline-block;
        }
        .comment-btn {
            text-align: right;
            line-height: 2;
            font-size: 0.75rem;
            cursor: pointer;
            &.placeholder {
                color: rgba($dark, 0.2);
            }
        }
    }
    .newsfeed-comment-section {
        overflow: hidden;
    }
    .story-update-content {
        margin-top: 1rem;
        .meta-info {
            @include font-size(small-m);
            color: $grey-dark;
            font-weight: 600;
            margin-bottom: 0.25rem;
            time {
                margin-right: 0.5rem;
            }
        }
    }
    .new-project-inside {
        position: relative;
        .preview-images{
            margin-left: -1rem;
            margin-right: -1rem;
            margin-top: -1rem;
            height: 200px;
            @include breakpoint(tablet){
                height: 400px;
            }
        }
    }
    //Placeholders
    .breadcrumb-placeholder {
        margin-bottom: 1rem;
        .user-image-placeholder {
            .ui.placeholder  {
                border-radius: 100%;
                height: 40px;
                width: 40px;
                float: left;
                margin-right: 1rem;
            }
        }
    }
    .feed-edited {
        font-size: 0.75rem;
        padding: 0.25rem 0;
    }    
}

//User labe
.user-label {
    position: absolute;
    top: 0.5rem;
    left: -0.5rem;
    line-height: 20px;
    @include breakpoint(tablet){
        top: 1rem;
        left: 0;
    }
    .user-image-wrapper {
        width: 40px;
        height: 40px;
        display: inline-block;
        float: left;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .user-name {
        @include font-size(small-l);
        color: #fff;
        font-weight: 700;
        padding: 0 1rem 0 0.5rem ;
        cursor: pointer;
        display: inline-block;
        height: 40px;
        background: rgba(0, 0, 0, 0.6);
        line-height: 3;
        padding-left: 30px;
        margin-left:-24px;
        border-radius: $border-radius;
    }
}

// ************************************** 
//
//          2a. Newsfeed Input          
//
// ************************************** 

.feed-add-images.button-element {
    //Add images button
    margin-top: 2rem;
    text-align: center;
    @include breakpoint(computer){
        .ui.button.single-button {
            width: 30%;
        }
    }
}

.newsfeed-post {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #E6E1D9;
    margin-left: -1em;
    margin-right: -1em;
    @include breakpoint(tablet){
        padding-top: 2rem;
        margin-left: 0;
        margin-right: 0;
    }
    overflow: hidden;
    .add-post {
        display: flex;
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;
        @include breakpoint(tablet){
            padding-left: 0;
            padding-right: 0;
        }
        .user-img {
            margin-right: 0.625rem;
           
            img {
                width: 40px;
                height: 40px;
            }
            .ui.placeholder {
                border-radius: 100%;
                .image {
                    height: 40px;
                }
            }
        }
        .post-input {
            background-color: #fff;
            width: calc(100% - 50px);
            border-radius: 5px;
        }
        .post-input-text {
            display: inline-block;
            width: 80%;
            @include font-size(small-m);
            padding-left: 0.75rem;
            white-space: nowrap;
            padding-top: 10px;
            @include breakpoint(tablet){
                padding-top: 0;
            }
        }
        .post-input-icon {
            display: inline-block;
            width: 20%;
            margin-left: auto;
            vertical-align: middle;
            img {
                width: 28px;
                height: 28px;
                display: block;
                margin-left: auto;
                margin-right: 0.625rem;
            }
        }
    }
    @include breakpoint(tablet) {
        margin-top: 1.25rem;
        padding: 1rem 0;
        @include box-shadow();
        .add-post {
            line-height: 2;
            overflow: hidden;
            padding: 0 1rem;
        }
    }
    //Placeholder
    &.post-placeholder {
        padding: 1rem;
        .ui.placeholder {
            .header {
                .user-image-placeholder {
                    width: 40px;
                    height: 40px;
                }
                .image {
                    height: 40px;

                    margin: 0;
                }
            }
        }
    }
}

//Sliding pane - mobile

.feedpost-slidingpane {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

//Add feedpost modal
.add-feedpost {
    .add-feedpost-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        @include breakpoint(tablet){
            padding: 0;
        }
       .feedpost-modal-title {
           display: block;
           font-weight: 500;
           font-family: $header-fontfamily;
           font-size: 1.25rem;
           text-transform: uppercase;
           flex-basis: 66.6%;
           text-align: center;
       }
       .feedpost-modal-close {
           padding: 0.5rem 0.25rem;
           align-self: flex-start;
           cursor: pointer;
       }
    }
    .ui.form textarea.add-feedpost-textarea {
        margin-bottom: 0.75rem;
        outline: none;
        resize: none;
        
    }
    //Desktop only - custom post feed button
    .ui.button.post-button {
        transition: 600ms;
        @include breakpoint(tablet){
            margin-bottom: 1rem;
        }
    }
}



//Comment section frontend
.comments-section {
    .comment-userimg {
        width: 42px;
        height: 42px;
        img {
            width: 100%;
            height: 100%;
            margin-left: auto;
        }
    }
    .ui.segment {
        padding: 0.5rem;
        margin-bottom: 0;
        .comment-user {
            font-size: 0.813rem;
            font-weight: 600;
        }
        .comment-content {
            font-size: 0.813rem;
        }
    }
    .comment-meta {
        font-size: 0.688rem;
        .comment-date {
            color: rgba($dark, 0.5);
            margin-right: 1rem;
        }
        .comment-answer-btn {
            font-weight: 600;
            color: $dark;
        }
    }
    .comment-answer-section {
        margin-top: 0.5rem;
        .comment-answer-show-all {
            font-size: 0.825rem;
            font-weight: 600;
        }
        .answer {
            margin-top: 0.5rem;
        }
    }
    .add-comment {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.5rem 0;
        border-top: 0.5px solid rgba($dark, 0.3);
        i.icon {
            font-size: 30px;
        }
    }
}

// ************************************** 
//
//  3. Fe Project (Frontend Project - Detail Page)              
//
// ************************************** 

//fe-project
.project-header, .story-header {
    position: relative;
    .user-label {
        z-index: 9;
        top: 1rem;
        left: 1rem;
        @include breakpoint(tablet){
            left: 1.5rem;
        }
    }
    .user {
        margin: 0 1rem;
        @media(min-width: 767px){

        }
    }
    .image {
        @include detailsHeader;
        .ui.placeholder {
            height: 100%;
            max-width: 100%;
            .image {
                width: 100%;
                position: relative;
            }
        }
    }
    .project-details {
        background-color: $grey-light;
        @include breakpoint(tablet) {
            background-color: transparent;
        }
        .ui.container {
            padding-top: 1rem;
            border-radius: $border-radius;
            @include breakpoint(tablet){
                padding: 1rem;
                background-color: $grey-light;
                @include box-shadow();
            }
        }
        margin-top: 0;
        @include breakpoint(tablet){
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;
        }
        .detail {
            white-space: nowrap;
            line-break: 1;
            &>div {
                display: inline-block;
            }
            svg {
                .st0 {
                    fill: none;
                    stroke: $dark;
                }
                width: 18px;
                height: 18px;
                margin-right: 0.15rem;
                @include breakpoint(tablet){
                    width: 22px;
                    height: 22px;
                    margin-right: 0.35rem;
                }
            }
            .active {
                svg {
                    fill: $red-light;
                    .st0 {
                        stroke: $red-light;
                        fill: $red-light;
                    }
                    .st1 {
                        fill: $red-light;
                    }
                }
            }
            &.detail-like, &.detail-comment {
                cursor: pointer;
            }
            .ui.image {
                width: 18px;
                height: 18px;
                display: inline-block;
                margin-right: 0.15rem;
                @include breakpoint(tablet){
                    width: 22px;
                    height: 22px;
                    margin-right: 0.35rem;
                }
            }
            .detail-sum {
                font-weight: 500;
                @include font-size(small-m);
                vertical-align: middle;
            }
        }
        .divider-vertical {
            background: rgba($dark, 0.2);
            height: 100%;
            width: 1px;
            margin: 0 auto;
        }

    }
    .vehicle-data-details {
        span {
            margin-top: 0;
            text-transform: uppercase;
            cursor: pointer;
            white-space: nowrap;
            @include font-size(medium-l);
            font-family: $header-fontfamily;
            font-weight: 500;
            display: block;
            text-align: center;
            //Arrow
            span {
                display: inline-block;
            }
        }
    }
    .user-actions {
        background-color: $grey-lighter;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        @include breakpoint(tablet){
            text-align: center;
        }
    }
}

.user-actions-icon {
    cursor: pointer;
    white-space: nowrap;
    .action {
        font-weight: 600;
        @include font-size(small-m);
        &.action-active {
            color: $red-light;
        }
    }
    .ui.image {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 0.25rem;
        @include breakpoint(tablet){
            width: 24px;
            height: 24px;
            margin-right: 0.5rem;
        }
    }
}


//Vehicle Data Table
.ui.table.vehicle-data-table {
    border-left: none;
    border-right: none;
    th {
        font-size: 0.875rem;
        font-weight: 600;
    }
    td {
        font-size: 0.875rem;
    }
}

.project-stories {
    .ui.grid > .row {
        margin-bottom: 2rem;
        border-radius: 4px;
        overflow: hidden;
    }
    .ui.grid {
        .row.story-card-item, .story-card-item-placeholder {
            @include box-shadow();
            margin-left: 1rem;
            margin-right: 1rem;
            padding-top: 0;
            position: relative;
            @include breakpoint(tablet){
                padding-top: 1rem;
            }
            .story-card-item-image.column {
                margin-bottom: 1rem;
                padding: 0;
                margin-top: 0;
                @include breakpoint(tablet){
                    padding-left: 0;
                    margin-top: -1rem;
                    margin-bottom: -1rem;
                }
                img {
                    overflow: hidden;
                }
                //Progressive imager loading class
                .story-card-item-loading-img {
                    min-height: 220px;
                    width: 100%;
                    @include breakpoint(tablet){
                        min-height: 280px;
                    }
                    @include breakpoint(computer){
                        min-height: 100%
                    }
                }
                //Placeholder image
                .ui.placeholder {
                    max-width: 100%;
                    width: 100%;
                    .image:not(.header):not(.ui){
                        min-height: 220px;
                        @include breakpoint(tablet){
                            min-height: 240px;
                        }
                        @include breakpoint(computer){
                            min-height: 340px;
                        }
                    }
                }
            }
            .story-card-item-content {
                .story-card-item-description {
                    @include font-size(small-l);
                }
                .story-card-item-plcaholder-header.ui.placeholder + .ui.placeholder {
                    margin-top: 1rem;
                    margin-bottom: 3rem;
                }
                .ui.grid.story-card-item-button {
                    @include breakpoint(tablet){
                        position: absolute;
                        bottom: 0.5rem;
                        left: 0;
                        right: 0;
                    }
                }
            }
            .social-buttons {
                background: #1B1C1D14;
                margin-left: -1rem;
                margin-right: -1rem;
                margin-bottom: -1rem;
                margin-top: 1rem;
                line-height: 1;
                @include breakpoint(tablet){
                    margin: 0;
                    line-height: 1.5;
                }
            }
        }
    }

}

.project-comments {
    background-color: $grey-lighter;
    @include breakpoint(tablet){
        //Stick to footer
        margin-bottom: -2rem;
    }
}

.table-top-header {
    width: 100%;
    height: 24px;
    padding-left: 1rem;
    span {
        width: 33.3%;
        display: inline-block;
        font-size: $font-s-l;
        font-weight: 600;
    }
}

.vehicle-data-table.ui.table.striped.celled {
    @include breakpoint(tablet){
        background: rgba($grey-dark, 0.2);
        padding: 1rem;
        border-bottom: 0;
    }
    &.basic-data {
        @include breakpoint(computer){
            margin-top: 24px;
        }
    }
    border-top: 0;
    @include breakpoint(computer){
        margin-top: 0;
    }
    tr th {
        border-left: 0;
    }
    th, td {
        width: 50%;
        @include breakpoint(computer){
            width: 33.3%;
        }
    }
}

.interview-content {
    // padding-top: 1rem;
    @include font-size(medium-s);
    .interview-header {
        font-family: $page-fontfamily;
        font-weight: 600;
        margin-bottom: 0.5rem;
        @include font-size(medium-s);
    }
}
// ************************************** 
//
//  4. Fe Story (Frontend Story - Detail Page)              
//
// ************************************** 

.story-header {
    position: relative;
    @include breakpoint(tablet){
        .image {
            height: 450px;
            .ui.image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

    }
    .story-submenu {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        padding: 0.5rem 0;
        @include breakpoint(tablet){
            margin-top: 1rem;
            width: 719px;
            margin-left: auto;
            margin-right: auto;
            border-radius: $border-radius;
            text-align: center;
            padding: 1rem;
        }
        @include breakpoint(computer){
            width: 927px;
        }
        .story-title {
            margin-left: 0.5rem;
        }
        .story-head {
            font-weight: 600;
        }
        .story-name {
            font-size: 0.85rem;
            @include breakpoint(tablet) {
                display: inline-block;
            }
        }
        .story-name, .story-header {
            @include font-size(medium-s);
        }
    }
}

h2.ui.header.story-main-header {
    @include font-size(large-s);
}

// ************************************** 
//
//  5. brandIndex.js         
//
// ************************************** 

.all-brands {
    margin-top: 1rem;
    .brand-category {

        .sorted-by {
            font-size: $font-massive;
            font-family: $header-fontfamily-secondary;
            margin-top: 3rem;
            margin-bottom: 1.5rem;
        }
        .brand-sorted {
            margin-bottom: 0.5rem;
            .brand {
                display: block;
                margin-bottom: 0.5rem;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
}

.brand-detail {
    margin-top: 1rem;
    .ui.list {
        .item {
            font-weight: 500;
            padding: 0.5rem 0;
        }
    }
}


// ************************************** 
//
//  5. User         
//
// ************************************** 

.user-data-container {
    position: relative;
}

.user-profile-header {
    .image  {
        height: 250px;
        @include breakpoint(tablet){
            height: 450px;
        }
        .ui.image {
            @include image-responsive(cover);
        }
        .ui.placeholder > .image {
            height: 250px!important;
            width: 100%;
            @include breakpoint(tablet){
                height: 450px!important;
                width: 100%;
            }
        }
    }
}

.user-info-section {
    @include breakpoint(tablet){
        position: absolute;
        left: 0;
        right: 0;
        bottom: 60px;
        background-color: $grey-light;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: $border-radius;
        box-shadow: 0px 0px 10px #00000059;
    }
    .user-image {
        width: 80px;
        height: 80px;
        margin-top: -40px;
        display: inline-block;
        @include breakpoint(tablet){
            width: 100px;
            height: 100px;
            margin-top: -50px;
        }
        .user-image-placeholder {
            .ui.placeholder {
                border-radius: 100%;;
                border: 3px solid #fff;
            }
            .image {
                width: 80px;
                height: 80px!important;
            }
        }
        .ui.image {
            @include image-responsive(cover);
            border: 3px solid #fff;
            float: left;
            @include breakpoint(tablet){
                border: 3px solid $grey-light;
            }
        }
    }
    .user-name {
        display: inline-block;
        overflow: hidden;
        line-height: 1.5;
        font-weight: 500;
        @include font-size(large-m);
        font-family: $header-fontfamily;
        margin-left: 0.5rem;
    }
    @include breakpoint(tablet){
        .follow-wrapper {
            line-height: 1.5;
            .ui.placeholder {
                margin-top: 0.5rem;
                .line {
                    height: 0;
                }
            }
        }
        .follow-wrapper, .send-msg {
            margin-top: 1rem!important;
        }
    }
    .followers {
        font-weight: 600;
        padding-right: 1rem;
        // float: left;
        // border-right: 1px solid rgba(34, 36, 38, 0.15);
        line-height: 2;
    }
    .follow-user {
        display: block;
        overflow: hidden;
        padding-left: 1rem;
        .ui.image {
            width: 24px;
            height: 24px;
        }
    }
    //Getting rid of row padding
    .ui.grid {
        .user-personal-info.row {
            padding-top: 0;
            margin-top: -1rem;
            @include breakpoint(tablet) {
                text-align: center;
            }
        }
    }
    .user-personal-info {
        padding-top: 0;
        .ui.header {
            color: $grey-dark;
            float: left;
            margin-right: 0.5rem;
            line-height: 1.33;
            @include breakpoint(tablet){
                margin-right: 0.5rem;
            }
            @include breakpoint(computer) {
                float: none;
                display: inline-block;
                margin-right: 1rem;
            }
        }
        .user-info {
            white-space: nowrap;
        }
    }
}

.live-posts.ui.grid {
    .live-posts-image {
        cursor: pointer;
    }
}

.user-projects-wrapper.ui.segment {
    box-shadow: none;
    border: none;
    .ui.header {
        text-transform: uppercase;
    }
    .user-projects {
        margin-left: -1rem;
        margin-right: -1rem;
        //Show if there is less than 3 slides
        &.no-sliding{
            .slick-track {
                margin: 0;
            }
        }
        .project {
            padding: 0 1rem;
            text-align: center;
        }
    }
    //Placeholder - show while data fetch
    .slides-placeholder {
        height: 250px!important;
    }
    .user-projects-placeholder.ui.placeholder {
        .line {
            height: 0;
        }
    }
}

.msg-popup-content {
    width: 500px!important;
}

.send-msg-container {
    @include breakpoint(computer) {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }
    .send-msg-user {
        .ui.image {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: inline-block;
            margin-right: 0.5rem;
        }
        .send-msg-user-name {
            @include font-size(medium-s);
            font-weight: 600;
        }
    }
}

// ************************************** 
//
//  6. 404 - Page not found          
//
// ************************************** 
.not-found-page {
    .ui.header {
        font-size: 5rem;
    }
    .not-found-error {
        color: $red-light;
        font-weight: 600;
    }
    .error-message {
        color: $grey-dark;
    }
}

// ************************************** 
//
//  7. Imprint          
//
// ************************************** 

.imprint {
    margin-top: 1rem;
    .imprint.ui.container {
        .ui.header {
            margin-bottom: 0.25rem;
        }
        p {
            margin-bottom: 0;
        }
    }
}

// ************************************** 
//
//  8. Partner        
//
// ************************************** 

.vetero-partner {
    margin-top: 1rem;
    a {
        color: $red-light;
        font-weight: 500;
    }
    .partner-logo {
        width: 100%;
        img {
            margin: 0 auto;
        }
    }
}

// ************************************** 
//
//  9. Activation  
//
// ************************************** 

//Activation page
.account-activation {
    p {
        @include font-size(medium-s)
    }
}

// ************************************** 
//
//  10. Password recovery
//
// ************************************** 

.password-recovery {
    .ui.form {
        @include breakpoint(tablet){
            max-width: 50%;
            margin: 0 auto;
            text-emphasis: center;
        }
    }
    p {
        @include font-size(medium-s)
    }
}

// ************************************** 
//
//  11. About / Privacy
//
// ************************************** 

.about-page, .privacy-page {
    margin-bottom: -1rem;
    .about-header {
        background-image: url(../images/about-page/about-header.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 280px;
        width: 100%;
        @include breakpoint(tablet){
            height: 350px;
        }
        @include breakpoint(computer){
            height: 450px;
            background-image: url(../images/about-page/about-header.jpg)
        }
    }

    p {
        @include font-size(medium-s);
        a {
            color: $red-light;
            font-weight: 500;
        }
    }

    .ui.grid img {
        height: auto;
    }

    .ui.list.about-list.bulleted {
        margin-top: 1rem;
        .item:before {
            color: $red-light!important;
        }
        a {
            color: $red-light;
            font-weight: 500;
        }
    }
    .privacy-cookies-list.ui.list.bulleted {
        line-break: anywhere;
    }
}

.about-page {
    overflow: hidden;
    margin-bottom: -1rem;
    h3.ui.header {
        text-transform: unset;
        @include font-size(large-m)
    }
    .introduction-content {
        padding-bottom: 48px;
        @include breakpoint(tablet){
            padding-bottom: 78px;
        }
        .ui.button {
            width: 100%;
            @include breakpoint(tablet){
                width: auto
            }
        }
    }
    .iconbox-wrapper {
        background-color:rgba($grey-lighter, 1);
        padding-top: 39px;
        padding-bottom: 39px;
        @include breakpoint(tablet){
            padding-top: 60px;
            padding-bottom: 60px;
        }
        h3.ui.header {
            margin-bottom: 39px;
        }
        .iconbox-item {
            margin-bottom: 2rem;
            display: flex;
            flex-basis: 50%;
            flex-wrap: wrap;
            flex-direction: column;
            @include breakpoint(tablet){
                display: flex;
                flex-basis: 33.3%;
                text-align: left;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                margin-bottom: 1rem;
            }
            .iconbox-icon {
                margin: 0 auto;
                margin-bottom: 1rem;
                @include breakpoint(tablet){
                    width: 45px;
                    height: 45px;
                    padding: 10px;
                    margin-right: 14px;
                    margin-bottom: 0;
                }
            }
            .iconbox-content {
                margin-top: 0;
                width: 100%;
                line-height: unset;
                text-align: center;
                @include breakpoint(tablet){
                    width: calc(100% - 45px);
                    text-align: left;
                }
            }
        }
    }
    .guide-section {
        .ui.grid > .row {
            margin-bottom: 40px;
            @include breakpoint(computer){
                margin-bottom: 80px;
            }
            &.tablet.vertically, &.mobile.vertically {
                flex-direction: column-reverse;
                @include breakpoint(tablet) {
                    flex-direction: row;
                }
            }
        }
        .ui.header {
            text-align: center;
            @include breakpoint(tablet) {
                text-align: left;
            }
        }
        .gif-element.ui.image {
            box-shadow: 0px 0px 10px #00000029;
            margin-top: 1rem;
            @include breakpoint(tablet){
                margin-right: auto;
                margin-left: auto;
                display: block;
            }
        }
        .mobile-link {
            display: none;
            text-align: center;
            margin-top: 2rem;
            @include breakpoint(tablet){
                display: none;
            }
        }
        .desktop-link {
            display: none;
            /*@include breakpoint(tablet){
                display: block;
            }*/
        }
        .desktop-link.story-link {
            display: none;
            @include breakpoint(tablet){
                display: block;
            }
        }
        .mobile-link.story-link {
            display: block;
            @include breakpoint(tablet){
                display: none;
            }
        }
    }
    .closing-section.iconbox-wrapper {
        .iconbox {
            margin-left: -1rem;
            margin-right: -1rem;
        }
        .iconbox-item {
            flex-basis: 100%;
            flex-wrap: nowrap;
            flex-direction: row;
            margin-bottom: 2rem;
            align-items: flex-start;
            padding-left: 1rem;
            padding-right: 1rem;
            @include breakpoint(tablet){
                flex-basis: 50%;
                margin-bottom: 38px;
                .iconbox-icon {
                    width: 70px;
                    height: 70px;
                    margin-right: 20px;
                    display: flex;
                    img {
                        padding: 6px
                    }
                }
                .iconbox-content {
                    line-height: unset;
                    text-align: center;
                    .iconbox-link {
                        margin-top: 1rem;
                        font-weight: 600;
                    }
                }
            }
            .iconbox-icon {
                padding: 14px;
                margin-right: 14px;
                @include breakpoint(tablet){
                    margin-right: 1.25rem;
                }
            }
            .iconbox-content {
                text-align: left;
                width: calc(100% - 70px);
                .iconbox-header {
                    text-transform: uppercase;
                    font-size: 1.25rem;
                    font-family: 'Barlow Condensed';
                }
            }
        }
    }
}

.about-page.detail-page {
    .closing-text {
        span {
            color: $red-light;
            font-size: $header-fontsize;
            font-family: $header-fontfamily;
            font-weight: 500;
            text-transform: uppercase;
            display: block;
            margin-bottom: 6px;
        }
    }
    .iconbox-wrapper {
        background: #fff;
        .ui.header {
            text-align: center;
            margin-bottom: 2rem;
            @include breakpoint(tablet) {
                text-align: left;
            }
        }
    }
}


.privacy-page {
    margin-top: 1rem;
    .ui.header:first-child {
        margin-top: 1.5rem;
    }
}

// ************************************** 
//
//  12. Blog
//
// ************************************** 

//Postlist page
.blog-tags {
    padding-top: 50px;
    .ui.label {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 0;
        text-transform: lowercase;
        letter-spacing: 1px;
        transition: 200ms;
        &:hover, &.active {
            background-color: $red-light;
            color: #fff;
        }
        &.active:hover {
            background-color: #ee1331;
            color: #fff;
        }
    }
    &.fetching {
        .ui.label {
            width: 90px;
            padding: 15px 0;
            transition: none;
            &:hover, &.active {
                background-color: $grey-light;
            }
            &.active:hover {
                background-color: $grey-lighter;
            }
        }
    }
}
.blog-index {
    .ui.grid {
        &>.row {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
    .blog-index-post {
        .blog-featured-image {
            height: 300px;
            .ui.image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .blog-content-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            .ui.button {
                width: 100%;
                @include breakpoint(computer){
                    width: auto;
                }
            }
        }
        .blog-content {
            .blog-content-tag {
                .ui.label {
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    margin-left: 0;
                    text-transform: lowercase;
                    letter-spacing: 1px;
                }
            }
            .blog-title {
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                @include breakpoint(tablet){
                    margin-top: 0;
                }
                h2.ui.header {
                    letter-spacing: 1px;
                    font-size: 2rem;
                }
            }
            .blog-teaser {
                margin-bottom: 2rem;
                p {
                    margin-bottom: 0;
                    display: inline-block;
                }
            }
        }
    }
    .blog-pagination {
        margin-top: 2rem;
    }
    //Fetching data
    &.fetching {
        .blog-featured-image {
            height: 100%;
            .ui.placeholder {
                height: 100%;
                max-width: 100%;
                .image:not(.header):not(.ui)  {
                    height: 100%;
                    max-width: 100%;
                }
            }
        }
        .blog-content-wrapper {
            padding: 20px 0;
            .ui.button {
                margin-top: 1rem;
            }
        }
    }
}

//Single Post Page
.blog-post-tag {
    margin-bottom: 1.5rem;
    @include breakpoint(tablet) {
        margin-bottom: 3rem;
    }
    .ui.label {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        text-transform: lowercase;
        letter-spacing: 1px;
    }
}
.blog-post-title {
    margin-right: 15%;
    h1.ui.header {
        margin-bottom: 0;
        font-size: 2.33rem;
        letter-spacing: 1.5px;
        line-height: 1;
        @include breakpoint(tablet){
            font-size: 3.25rem;
        }
    }
}
.blog-post-author {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.blog-post-wrapper {
    .ui.container {
        @include breakpoint(tablet){
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @include breakpoint(computer){
            padding-left: 6rem;
            padding-right: 6rem;
        }
    }
    .blog-post {
        padding-bottom: 40px;
        padding-top: 40px;
        @include breakpoint(tablet){
            padding-bottom: 80px;
            padding-top: 0;
        }
        .blog-post-content  {
            h2 {
                font-size: 1.75rem;
                @include breakpoint(tablet){
                    font-size: 2rem;
                }
            }
            h3 {
                font-size: 1.4rem;
                @include breakpoint(tablet){
                    font-size: 1.66rem;
                }
            }
            h4 {
                font-size: 1.25rem;
                @include breakpoint(tablet){
                    font-size: 1.4rem;
                }
            }
            blockquote {
                position: relative;
                padding-left: 2rem;
                padding-right: 2rem;
                font-style: italic;
            }
            img {
                max-width: 100%;
                height: auto;
            }
            figure {
                margin: 0;
                max-width: 100%!important;
            }
            .aligncenter {
                display: block;
                margin: 0 auto;
            }
            a {
                color: $red-light;
                u {
                    text-decoration: none;
                }
            }
            table {
                border: 1px solid rgba(34, 36, 38, .15);
                border-radius: 4px;
                margin: 0 auto;
                thead {
                    background-color: rgba(0, 0, 0, .15);
                    text-align: center;
                    td {
                        padding: 0.5rem;
                        border-right: 1px solid rgba(34, 36, 38, .15);
                    }
                }
                tbody {
                    tr {
                        border-bottom: 1px solid rgba(34, 36, 38, .15);
                        padding: 1rem;
                        td {
                            padding: 0.5rem 0.5rem;
                            border-right: 1px solid rgba(34, 36, 38, .15);
                        }
                    }
                }
            }
            .custom-col {
                @include breakpoint(tablet){
                    width: 33%;
                    display: inline-block;
                    vertical-align: text-top;
                }
            }
        }
        &.fetching {
            .ui.placeholder {
                max-width: 100%;
                .image {
                    height: 200px;
                    max-width: 100%;
                }
            }
        }
    }
}

.post-fetching {
    .header-image {
        .header-image-content {
            height: 100%;
            .ui.placeholder {
                max-width: 100%;
                height: 100%;
            }
        }
    }
    .blog-post-wrapper {
        padding-top: 80px;
    }
}

.blog-comments-container {
    background-color: rgba($grey-lighter, 1);
    margin-bottom: -1rem; //Stick to the footaah :D 
    padding: 2rem 0;
    //Blog comments - get rid of container padding
    .ui.container > .ui.container {
        margin-left: 0!important;
        margin-right: 0!important;
    }

}

// ************************************** 
//
//          13. Forum                
//
// ************************************** 

//Forum index
.forum-index {
    margin-top: 2rem;
    .forum-description {
        margin-bottom: 1rem;
    }
    .forum-main-threads, .forum-brand-categories {
        margin-top: 4rem;
    }
    .forum-main-threads {
        .thread {
            cursor: pointer;
        }
    }
    .forum-brand-categories {
        .brand-categories-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .brand-categories {
            .brand-category {
                .sorted-letter {
                    font-size: $font-massive;
                }
                .categories {
                    .category {
                        cursor: pointer;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

//Forum Detail
.forum-post-detail {
    .forum-post-title {
        margin-top: 2rem;
        margin-bottom: 3.5rem;
        h1.ui.header {
            text-transform: unset;
            margin-bottom: 0.5rem;
        }
    }
    .switching-page {
        position: static;
        margin-bottom: 4rem;
        .ui.loader {
            position: relative;
        }
    }
    .post-answer-section {
        h2 {
            margin-bottom: 2rem;
        }
        .button-element {
            margin-top: 2rem;
            margin-bottom: 2.5rem;
            @include breakpoint(tablet){
                text-align: left;
            }
        }
    }
    .ui.pagination.menu {
        margin-bottom: 4rem;
    }
}

//Forum create post
.forum-create-post {
    padding-top: 1rem;
    .question-type {
        .ui.button {
            display: block;
            width: 100%;
            margin-bottom: 1rem;
            @include breakpoint(tablet){
                margin-right: 1rem;
                margin-bottom: 0;
                display: inline-block;
                width: auto;
            }
        }
        margin-bottom: 2.75rem;
    }
    .choose-project {
        margin-bottom: 3.625rem;
    }
    .ui.form.choose-vehicle {
        .fields.choose-vehicle-autosuggestion {
            margin-left: 0;
            &>div {
                width: 50%;
                padding-right: 0.5rem;
            }
        }
    }
    .question-content {
        //Add medias
        .button-element {
            text-align: center;
            width: 100%;
            @include breakpoint(tablet){
                text-align: left;
                width: 80%;
            }
        }
    }
    .post-tags {
        margin-top: 2.75rem;
        .post-tags-description {
            font-size: $font-m-xs;
        }
        .tags {
            &>span {
                font-size: $font-m-xs;
                font-weight: bold;
            }
            .ui.label {
                margin-left: 0.5rem;
            }
        }
        .add-tag {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            margin-left: 1rem;
            width: 23px;
            height: 23px;
        }
    }
}

//Forum overview
.forum-overview {
    margin-top: 2rem;
    padding-bottom: 2rem;
    .forum-description {
        margin-bottom: 1rem;
    }
}

.forum-threads {
    padding-top: 3rem;
}

// ************************************** 
//
//          14. Professionals                
//
// ************************************** 
.new-professional {
    .logo-upload {
        width: 100%;
        @include breakpoint(tablet){
            width: 50%;
        }
    }
    .video-upload {
        .images {
            .image-item {
                flex-basis: 100%;
                padding-bottom: 66.6%;
                @include breakpoint(tablet){
                    flex-basis: 50%;
                    padding-bottom: 33.3%;
                }
            }
        }
    }
    .button-element {
        text-align: left;
    }
    .tagging {
        .tagging-description {
            font-size: 0.875rem;
        } 
        .tags {
            font-weight: 600;
            font-size: 0.875rem;
        }
        .add-tag {
            display: inline-block;
            vertical-align: middle;
            margin-left: 1rem;
            cursor: pointer;
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.specialists-page {
    .choosed-tags {
        margin-top: 1rem;
        margin-bottom: 1rem;
        min-height: 28px;
    }
    .list-map, .leaflet-container {
        height: calc(100vh - 322px);
        
    }
    .list-map {
        margin-bottom: -1rem;
        .marker-own-location {
            width: 15px;
            height: 15px;
            background-color: $red-light;
            border-radius: 100%;
        }
        &.map-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
        }
    }
    .specialists-list {
        .fetching-list {
            margin-top: 2rem;
            position: relative;
        }
    }
    .specialists-detail-content {
        .specialist-description {
            display: flex;
            flex-direction: column-reverse;
            @include breakpoint(tablet){
                flex-direction: row;
                .specialist-text {
                    flex-basis: 80%;
                }
                .specialist-logo {
                    flex-basis: 20%;
                }
            }
        }
    }
    .specialists-detail-services {
        ul {
            li {
                margin-bottom: 0.5rem;
                display: block;
                position: relative;
            }
            li:before {
                content: '';
                background-color: $red-light;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                position: absolute;
                left: -1rem;
                margin-top: 7px;
            }
        }
    }
    .specialists-video {
        .video-preview {
            @include breakpoint(computer){
                width: 700px;
            }
            margin: 0 auto;
            position: relative;
            cursor: pointer;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                height: 100%;
                z-index: 1;
            }
            i.icon {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: 58px;
                z-index: 2;
                transform: translateY(-25%);
            }
        }
    }
    .specialists-contact {
        .contact-map, .leaflet-container {
            height: 254px;
        }
    }
}