@import '../../../css/mixins.scss';
@import '../../../css/settings.scss';

//dots dropdown menu
.header-dropdown {
    .ui.dropdown {
        width: 38px;
        height: 38px;
        display: flex;
        .ui.image {
            width: inherit;
            height: inherit
        }
        .item {
            a {
                padding: 0.8rem
            }
        }
    }
}

.main-header.ui.menu, .dashboard-header.ui.menu {
    @include topFixed();
    margin-bottom: 0;
    margin-top: 0;
}

.main-header.ui.menu.secondary {
    background: linear-gradient(90deg, rgba($dark-lighter, 1) 35%, rgba($dark, 1) 100%);
}

.dashboard-header.ui.menu.secondary {
    background: linear-gradient(90deg, rgba($red-light, 1) 35%, rgba($red-darker, 1) 100%);
}

.dashboard-header.ui.menu.secondary.menu-mobile.three.item, .main-header.ui.menu.secondary.menu-mobile.three.item.main-header-secondary {
    .item.left {
        width: 20%;
        i.icon.sidebar, i.icon.angle.left {
            margin-right: auto;
            margin-left: 1rem;
            color: #fff
        }
    }
    .item.left.logo {
        width: 20%;
        margin-left: 1rem;
    }
    .item.top-header-title {
        width: 60%;
        white-space: nowrap;
    }
    .item.right {
        width: 20%;
    }
}

.main-header.ui.menu.secondary.menu-mobile.three.item {
    margin-top: 0;
    .item.left{
        &.sidebar-item {
            width: 20%;
        }
        i.icon.sidebar, i.icon.angle.left {
            margin-right: auto;
            margin-left: 1rem;
            color: #fff;
        }
    }
    .item.left.logo {
        width: 30%;
        margin-left: 1rem;
        .ui.image {
            height: 24px;
        }
    }
    .item.top-header-title {
        width: 40%;
    }
    .item.right {
        width: 30%;
    }
}

#root .main-header.ui.menu.secondary, .dashboard-header.ui.menu.secondary {
    margin-right: 0;
    margin-left: 0;
    border: none;
    .item {
        &.dropdown-item {
            .ui.image {
                width: 20px;
                height: 34px;
            }
        }
        &.dropdown-item:hover {
            background: none;
        }
    }
    .left.item.logo, .right.item.top-header-user {
        @include breakpoint(computer){
            padding-left: 0;
            padding-right: 0;
        }
    }
    a.item  {
        @include nav-links();
        color: #fff;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        span { // Menu items
            font-weight: 500;
        }
        &.logo, &.left, &.sidebar-item.item {
            @include breakpoint(tablet){
                width:20%;
            }
            .ui.image {
                width: 100px;
                margin-right: auto;
                margin-left: 1rem;
                @include breakpoint(tablet) {
                    width: 132px;
                    margin-left: 0;
                }
                @include breakpoint(computer){
                    margin-right: auto;
                }
            }
            &:hover {
                background: none;
                color: $dark;
            }
            .notifications {
                height: 26px;
                width: 26px;
                margin-right: 0.5rem;    
                &.mobile-notifications {
                    border: 1px solid #fff;
                    background: transparent;  
                    border-radius: 100%;
                    color: #fff;
                    .notifications-amount {
                        line-height: 1.5;
                        text-align: center;
                    }
                }
            }
        }
        &.top-header-user {
            width:20%;
            .login-item {
                width: 40px;
                height: 40px;
                margin-left: auto;
                cursor: pointer;

            }
            .user-image, .login-item {
                margin-left: auto;
                .ui.image {

                    width: 40px;
                    height: 40px;
                }
            }
            .user-image {
                .ui.image {
                    border: 2px solid transparent;
                    &:hover {
                        border: 2px solid #fff;
                    }
                }
            }
            &:hover {
                background: none;
                color: $dark;
            }
            &.active, &:active {
                background: none;
                .user-image {
                    .ui.image  {
                        border: 2px solid #fff;
                    }
                }
            }
            .notifications {
                height: 26px;
                width: 26px;
                background-color: $red-light;
                border-radius: 100%;
                margin-right: 0.5rem;
                margin-left: auto;
                .notifications-amount {
                    line-height: 1.5;
                    text-align: center;
                }
            }
        }
    }
    .top-header-title {
        h1{
            color: #fff;
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1.2;
            font-size: $header-fontsize;
        }
    }
    .sidebar-item.item {
        padding-left: 0.875rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .menu-item {
        &.item {
            margin-right: 0.5rem;
            border-radius: 0;
            padding-top: 2px;
            padding-bottom: 2px;
            margin-right: 0;
            border-right: 1px solid rgba(#fff, 0.5);
            span {
                border-bottom: 2px solid transparent;
            }
            &:nth-child(2) {
                border-left: none;
                margin-right: 0;
            }
            &:last-child {
                border-right: 0!important;
            }
            .ui.image {
                width: 32px;
                height: 32px;
            }
            &.active, &:first-child.active, &:hover {
                background: none!important;

                span {
                    border-bottom: 2px solid $red-light;
                    // margin-top: 2px;
                    display: block;
                }
            }
            &:hover {
                color: #fff;
            }
        }
    }
    //Menu Grid
    .ui.grid {
        margin-bottom: 0;
        .column:not(.row){
            padding-bottom: 0;
            .left.item {
                padding-left: 0;
                padding-right: 0;
                .ui.image {
                    margin-left: 0;
                }
            }
        }
        .column.left {
            padding-left: 0;
        }
    }
}

.ui.secondary.menu.sub-menu {
    @include breakpoint(tablet){
        margin: 0;
        padding: 0.5rem 0;
        .left.item {
            .sub-menu-buttons {
                margin-right: auto;
                margin-left: 1rem;
                cursor: pointer;
            }
        }
        &.sub-menu-one-item {
            .left.item {
                .sub-menu-buttons {
                    @include breakpoint(tablet){
                        margin-left: 0;
                    }
                    @include breakpoint(computer){
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
    a.item.top-header-title:hover, a.left.item {
        background: transparent;
    }
}

.dashboard-header.ui.menu.secondary.header-menu {
    a.item.menu-item {
        &.active, &:first-child.active, &:hover {
            background: none!important;
            span {
                border-bottom: 2px solid #fff;
                // margin-top: 2px;
            }
        }
    }
}

//DashboardHeader.js
.custom-sidebar.ui.menu.left.sidebar.secondary, .custom-sidebar.ui.menu.secondary {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding-top: 14px;
    z-index: 9999;
    @include breakpoint(computer){
        padding-top: 0;
        background-color: rgba($grey-dark, 0.2);
        position: static;
    }
    @include breakpoint(tablet){
        padding-top: 0;
    }

    a.item {
        line-height: 1.75;
        .item-wrapper {

            padding: 10px 0;
            border-bottom: 1px solid rgba($dark, 0.1);
            overflow: hidden;
        }
        .ui.label {
            font-size: 1rem;
            border-radius: 12px;
            float: right;
            padding: 0.33rem 0.85rem;
        }
        &:hover, &:active {
            background-color: rgba($dark, 0.1);
        }
    }
    &.hideable {
        .sidebar-menu {
            height: 100%;
            padding-top: 1rem;
            background-color: rgba($grey-dark, 0.2);
        }
    }

}

#root .mobileDashboardContainer {
    margin-top: 48px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.sidebar-overlay {
    &.sidebar-isOpen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 500ms;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.85);
        z-index: 999;
    }
}

.top-header-title span.h1 {
    font-family: 'Barlow Condensed', sans-serif;
    color: #fff;
    margin: 0 auto;
    padding: 0;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.375rem;

    @include breakpoint(tablet){
        color: #36220F;
        text-transform: none;
        line-height: 1.28571429em;
        font-weight: 500;
        min-height: 1rem;
        font-size: 1.563rem;
    }
}

//Subheader
.edit-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    .edit-header-main {
        background-color: $backend-edit-header-bg-color;
        padding: 1rem;
    }
    .ui.buttons {
        width: 100%;
        @include breakpoint(tablet){
            width: auto;
        }
        .ui.button {
            @include breakpoint(tablet){
                margin-right: 0.5rem;
            }
            border-color: #fff!important;
            border: 1px solid #fff;
            color: #fff!important;
            &:hover {
                background: #fff!important;
                color: $dark!important;
            }
            &:focus {
                background: transparent!important;
                outline: none;
            }
            @include breakpoint(tablet){
                width: auto;
            }
        }
    }
    .not-saved {
        font-size: $font-m-s;
        font-weight: 500;
        white-space: nowrap;
        display: none;
        @include breakpoint(largeMonitor){
            display: inline-block;
        }
    }
}

.stepmenu.ui.menu.pointing.secondary {
    justify-content: center;
    align-items: center;
    background: #fff;
    margin: 0;
    a.item {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        @include breakpoint(tablet){
            padding: 1.5rem;
        }
        &.validation-error:not(.active) {
            color: rgba($red-darker, 1);
            font-weight: bold;
        }
    }
    .preview-button {
        position: absolute;
        right: 1rem;
        display: none;
        @include breakpoint(tablet){
            display: block;
        }
    }
} 