@import '../../css/mixins.scss';


.popup-overlay {
    //Negative margin to get full height of the screen
    margin-top: -10rem;
    z-index: 9999!important;
}


.popup-content {
    padding: 0!important;
    border-radius: 5px;
    width: 92%!important;
    .popup-content-inside {
        height: 100%;
        padding-bottom: 2rem;
   

        @include breakpoint(tablet) {
            padding-top: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    @include breakpoint(tablet){
        width: 500px!important;
        .popup-content-inside {
         
        }
    }
    @include breakpoint(computer){
        .popup-content-inside {
        
        }
    }
    @include breakpoint(computer) {
        width: 700px!important;
        &.small-popup-content {
            width: 500px!important;
        }
        &.medium-popup-content {
            width: 700px!important;
 
            .popup-content-inside {
             
            }
        }
        &.larger-popup-content {
            width: 900px!important;
            .popup-content-inside {
         
            }
        }
    }
    .popup-header {
        background-color: rgba($grey-dark, 0.15);
        padding: 14px;
        &.popup-header-white {
            background-color: rgba($grey-dark, 0);
            border-bottom: 1px solid rgba($grey-dark, 0.55);
        }
        .ui.header {
            margin-bottom: 0;
        }
        @media(min-width: 768px){
            padding-left: 2rem;
            padding-right: 2rem;
            margin-left: -1rem;
            margin-right: -1rem;
            padding-top: 1rem;

        }
    }
}

.close-icon-popup {
    cursor: pointer;
}

/* animation */

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }
  .popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }