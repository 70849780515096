/* Comments.scss */
@import '../../css/settings.scss';
@import '../../css/mixins.scss';

/* ************************************** */
/*
    List of components
    1. Comments.js + Answer.js 
    2. CommentsInput.js
    
*/
/* ************************************** */

/* ************************************** */
/*
    1. Comments.js + Answer.js
*/
/* ************************************** */

.comments-wrapper {
    //User not logged in message
    margin-bottom: 10vh;
    .not-logged {
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;
        position: fixed;
        bottom: 0;
        left: 0; 
        right: 0;
        background: #fff;
        z-index: 99;
        border-top: 1px solid rgba($dark, 0.5);
        img {
            width: 30px;
            margin-right: 0.5rem;
        }
    }
    .ui.message.no-comments-message {
        justify-content: center;
        i.icon, .content {
            flex: none
        }
    }
    //Comment 
    .ui.comments {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        .comment {
            .author, .text {
                @include font-size(small-l);
            }
            .metadata {
                font-size: 0.813rem;
            }
            //Comment body
            .content {
                .comment-inner-wrapper {
                    background-color: #F7F7F7;
                    border-radius: 4px;
                    padding: 0.33rem;
                }
            }
            //Comment action - answer
            .answer-btn {
                color: $dark;
                @include font-size(small-s);
                font-weight: 600;
                cursor: pointer;
            }
            //Comment Answer 
            .ui.comments {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                .comment {
                    margin-bottom: 1.25rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    //Show more comments 
    .comment-answer-show-all, .comment-show-all {
        font-weight: 600;
        font-size: 14px;
        color: $red-light;
        cursor: pointer;
        display: block;
        margin-bottom: 1rem;
        color: $red-darker;
        @include breakpoint(tablet){
            margin-bottom: 2rem;
        }
    }
    //Blog Comments 
    &.blog-comments {
        margin-bottom: 0;
        .not-logged {
            position: relative;
            border: none;
            height: auto;
            background: transparent;
            justify-content: flex-start;
            margin-bottom: 2rem;
        }
        .ui.comments {
            //Comment body
            .content {
                .comment-inner-wrapper {
                    background-color: #fff;
                }
            }
        }
    }
}


//Mobile answer section - slidingpane
.answer-slidingpane-overlay.slide-pane__overlay.overlay-after-open {
    background: transparent;
    .slide-pane {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3)
    }
    .slide-pane__title {
        position: absolute;
        top: 1rem;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    .slide-pane__close {
        right: 0;
        left: 0;
    }
}

/* ************************************** */
/*
    2. CommentsInput.js
*/
/* ************************************** */

.textfield-comment-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    min-height: 10vh;
    z-index: 99;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba($dark, 0.5);
    //Textarea
    .textfield {
        flex-basis: 100%;
        margin-right: 0.5rem;
        textarea {
            padding: 0.625rem;
            border-radius: 4px;
            box-shadow: none;
            resize: none;
            width: 100%;
            @include font-size(small-l);
            &:focus {
                outline: none;
            }
        }
    }
    //Send button
    .send.comment-icon {
        cursor: pointer;
        img {
            width: 30px;
            height: 30px;
            transition: 300ms;
        }
    }
    //Blog comment field
    &.blog-comment-field {
        padding: 0;
        position: relative;
        border: none;
        background: transparent;
        min-height: auto;
        .textfield {
            @include breakpoint(tablet){
                margin-left: 0.5rem;
            }
            textarea {
                border: none;
            }
        }
        .user-avatar {
            display: none;
            width: 44px;
            height: 44px;
            @include breakpoint(tablet) {
                display: block;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        .send.comment-icon {
            img {
                background-color: $red-light;
                border-radius: 4px;
                width: 50px;
                height: 36px;
                @include breakpoint(tablet){
                    width: 46px;
                }
                cursor: pointer;
                padding: 7px;
                &.disabled {
                    cursor: default;
                }
            }
        }
    }
}

