@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.filter-overlay {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 999;

        background: rgba(0, 0, 0, 0.65);
    
}

.ui.secondary.menu.topheader-sort {
    background-color: #F3F1ED;
    margin-bottom: 0;
    .item {
        @include font-size(small-l);
        font-weight: 600;
        &.dropdown-item:hover {
            background: none;
        }
        &:hover {
            background: none;
        }
        .ui.image {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
        .active-filter {
            color: $red-light;
        }
    }
}

.headroom-wrapper {
    @include breakpoint(tablet){
        margin-top: 1rem;
    }
    &.filter-show > .headroom--unpinned.headroom--scrolled{
        margin-top: 1rem;
    }
}

.filter-show {
    .headroom {
        z-index: 9999;
    }
}

.headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    @include breakpoint(tablet){
        -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,1);
        -moz-box-shadow: 0px 0px 10px -2px rgba(0,0,0,1);
        box-shadow: 0px 0px 10px -5px rgba(0,0,0,1);
    }
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    @include breakpoint(tablet){
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(100%);
    @include breakpoint(tablet){
        max-width: 700px;
        top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }
  }
  .headroom--unfixed {
    @include breakpoint(tablet){
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
  }

.top-bar {
    position: relative;
    z-index: 9;
    //Footer nav margin fix - override Semantic margins
    .ui.secondary.menu {
        margin-left: 0;
        margin-right: 0;
        .gallery-view i, .list-view i {
            font-size: 20px;
            margin: 0;
        }
        .item {
            &.gallery-view, &.list-view {
                padding-left: .25rem;
                padding-right: .25rem;
            }
            &.toggle-filter {
                padding-left: .25rem;
                span {
                    display: none;
                    @include breakpoint(tablet){
                        display: block;
                    }
                }
            }
        }
        .topheader-divider {
            height: 20px;
            width: 1px;
            background-color: rgba($dark, 0.2);
            margin: 0.8rem 10px;
            line-height: 1;
            @include breakpoint(tablet){
                margin-right: 20px;
                margin-left: 20px;
            }
        }
    }
    .filter-options {
        background: #fff;
        position: absolute;
        top: 2.6rem;
        left: 0;
        right: 0;
        padding: 1rem 0;
        @include breakpoint(tablet){
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

//Filter search - inline form for medium devices
.inline-searchform {
    
    label, .ui.form .inline-field > label  {
        float: left;
        line-height: 2.5;
        cursor: pointer;
    }
    .inline-select {
        width: 60%;
        margin-left: auto;
    }
    .ui.form .field .ui.input, .ui.form .inline.field:not(.wide) .ui.input {
        display: block;
        width: 60%;
        margin-left: auto;
    }
    .ui.form .field .ui.input input {
        width: 100%;
    }
}

//ForumFilter
.filter-options.forum-filter {
    @include breakpoint(tablet){
        padding-bottom: 2.5rem;
    }
    .custom-tab-menu {
        .ui.pointing.menu {
            margin-bottom: 2rem;
        }
    }
    .filter-tags {
        margin-top: 2rem;
        strong {
            display: block;
            font-size: $font-m-xs;
            font-weight: 600;
        }
        .ui.label {
            margin-top: 0.5rem;
            margin-right: 0.5rem;
            margin-left: 0rem;
        }
        .add-tag {
            display: inline-block;
            vertical-align: middle;
            width: 22px;
            height: 22px;
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }
}

.choosed-tags {
    margin-top: 0.5rem;
    span strong {
        font-size: 0.875rem;
    }
}