
@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.add-to-home {
    position: fixed;
    z-index: 99999;
    background:rgba(0, 0, 0, 1);
    padding: .5rem 1rem;
    color:#fff;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include breakpoint(tablet){
        bottom: 1rem;
        right: 1rem;
        left: auto;
        border-radius: 4px;
        padding: 1.25rem 1.5rem;
    }
    .add-to-home-icon {
        width: 44px;
        height: 44px;
        margin-right: 1rem;
        @include breakpoint(tablet){
            width: 60px;
            height: 60px;
        }
    }
    .add-to-home-close {
        margin-left: auto;
        cursor: pointer;
        @include breakpoint(tablet){
            margin-left: 1.5rem;
        }
    }
}