@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.flex-gallery-wrapper {
    @include breakpoint(largeMonitor) {
        width: 1119px;
        margin-left: auto!important;
        margin-right: auto!important;
    }
}

.flex-gallery {
    margin-left: -1px;
    margin-right: -1px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
        padding-left: 1px!important;
        padding-right: 1px!important;
        padding-bottom: 2px!important;
        /*@include breakpoint(largeMonitor){
            transition: transform 400ms;
            cursor: pointer;   
            &:hover {
                transform: scale(1.1);
                position: relative;
                z-index: 9;
            }    
        }*/
    }
    .flex-gallery-items {
        display: flex;
        flex-wrap: wrap; 
        .flex-gallery-image {
            flex-basis: 33.3%;
            @include breakpoint(tablet){
                flex-basis: 25%;
            }  
        }
        &.last-row {
            .flex-gallery-image {
                flex-basis: 25%;
                height: 150px; // Cut
                &.last {
                    flex-basis: 50%;
                }
            }
        }
    }
    .flex-gallery-items-wrapper {
        display: flex;
        flex-wrap: wrap; 
        .flex-gallery-image {
            @include breakpoint(tablet){
                height: 270px;
            }
            @include breakpoint(computer){
                height: 500px;
            }
            @include breakpoint(largeMonitor){
                height: 560px;
            }
        }
        .flex-gallery-image.flex-gallery-big-image {
            flex-basis: 60%;
            @include breakpoint(tablet){
                flex-basis: 50%;
            }
            @include breakpoint(computer){
                flex-basis: 50%;
            }
        }
        .flex-gallery-vertical {
            flex-basis: 40%;
            padding: 0;
            @include breakpoint(tablet){
                flex-basis: 50%;
            }
            @include breakpoint(computer){
                flex-basis: calc(100% - 50%);
                img {
                    height: 100%;
                }
            }
            img {
                height: 50%;
                display: block;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        &.flex-gallery-items:nth-child(2){
            
        }
        .flex-gallery-vertical-two-items {
            display: flex;
            height: 50%;
            img {
                width: 50%;
                height: 100%;
                display: block;
            }
            
        }
        .flex-gallery-vertical-three-items {
            display: flex;
            height: 50%;
            img {
                width: 33.3%;
                height: 100%;
                display: block;
            }
        }
    }
}
