@import '../../../../css//settings.scss';
@import '../../../../css//mixins.scss';

.professional-stepmenu.ui.pointing.menu {
   justify-content: center;
   font-size: 0.8125rem;
   justify-content: space-between;
   margin-left: -1rem;
   margin-right: -1rem;
   @include breakpoint(tablet){
      justify-content: space-evenly;
      margin: 0;
      .item {
         padding-left: 0.8rem;
         padding-right: 0.8rem;
      }
   }
   .item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
   }
   .validation-error {
      color: $red-darker!important;
      font-weight: bold!important;
   }
}

.professional-not-active {
   margin-bottom: 2rem;
}