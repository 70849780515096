@import '../../css/mixins.scss';

@include breakpoint(tablet){
    .slick-slider:hover {
        .custom-arrow {
            opacity: 1;
            transition: 600ms;
        }
    }
}

.custom-arrow {
    opacity: 0;
    &.next, &.prev {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-150%);
        z-index: 999;
        width: 26px;
        height: 26px;
        cursor: pointer;
        .ui.image {
            width: 100%;
            height: 100%;
        }
    }
    &.next {
        right: -40px;
        left: auto;
    }
    &.prev {
        left: -40px;
        right: auto;
    }
}