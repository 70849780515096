/* PreviewGallery.scss */
@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.preview-gallery, .single-media {
    @include breakpoint(tablet){
        margin-left: -0.25rem;
        margin-right: -0.25rem;
    }
    .images {
        display: flex;
        flex-wrap: wrap;
        .image-item {
            //Mobile - 3 images per row
            flex-basis: 33.3%;
            position: relative;
            padding-bottom: 33.3%;
            @include breakpoint(tablet) {
                //Tablet / Desktop - 5 images per row
                flex-basis: 20%;
                padding-bottom: 20%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                padding: 0.25rem;
            }
            //Lightbox enabled
            &.is-lightbox {
                cursor: pointer;
            }
            //Placeholder - uploading media
            .ui.placeholder {
               position: absolute;
               width: 100%;
               height: 100%;
                .image:not(.header):not(.ui) {
                    height: 0;
                    margin: 0.25rem;
                }
            }
            // Icon (like play button)
            i.icon.play {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                font-size: 5rem;
                height: auto;
            }
            //Progress bar
            .uploading-media {
                .ui.progress {
                    position: absolute;
                    bottom: 0;
                    left: 0.25rem;
                    right: 0.25rem;
                    border-radius: 0;
                    z-index: 999;
                    &:first-child {
                        margin: 0!important;
                    }
                    .bar {
                        height: 1rem;
                    }
                }
            }
        }
        //3 images per row
        &.cols-3 {
            .image-item {
                flex-basis: 50%;
                padding-bottom: 50%;
                @include breakpoint(tablet) {
                    //Tablet / Desktop - 3 images per row
                    flex-basis: 33.3%;
                    padding-bottom: 33.3%;
                }
            }
        }
        //4 images per row
        &.cols-4 {
            .image-item {
                flex-basis: 50%;
                padding-bottom: 50%;
                @include breakpoint(tablet) {
                    //Tablet / Desktop - 3 images per row
                    flex-basis: 25%;
                    padding-bottom: 25%;
                }
            }
        }
    }
    //Deletable images
    &.deletable {
        .image-item {
            position: relative;
            .image-delete {
                display: inline-block;
                margin: 0;
                position: absolute;
                cursor: pointer;
                right: 10px;
                top: 9px;
                background-color: $grey-light;
                border-radius: 100%;
                padding: 0.75rem;
                text-align: center;
                z-index: 999;
                font-size: 0.986rem;
                vertical-align: middle;
                img {
                    width: 16px;
                    height: 16px;
                    margin: auto;
                    display: block;

                }
            }
        }
    }
    &.sortable {
        .sortable-wrapper {
            position: relative;
            flex-basis: 33.3%;
            .image-item.sortable {
                padding-bottom: 66.6%;
                img {
                    cursor:move;
                }
                .image-delete {
                    img {
                        cursor: pointer;
                    }
                }
            }
            .image-item-description {
                font-size: $font-s-l;
                padding: 0 0.5rem;
                margin-bottom: 1rem;
                .content-gallery-description-textarea.ui.form {
                    margin-top: 4px;
                    position: relative;
                    textarea {
                        font-size: $font-s-l;
                        padding: 0;
                        border: none;
                        resize: none;
                        padding-right: 3rem;
                        max-height: 60px;
                        &::placeholder {
                            color: $dark;
                        }
                    }
                    .textarea-counter {
                        position: absolute;
                        top: -3px;
                        right: 0;
                        &.counter-error {
                            color: $red-darker;
                        }
                    }
                }
            }
        }
    }
}

.sortableHelper {
    .image-item-description, .image-delete {
        display: none!important;
    }
}

//PreviewGallerySecondary
.preview-gallery-secondary {
    &.gallery-element{
        cursor: pointer;
        overflow: hidden;
        @include breakpoint(tablet){
            .top-image {
                width: calc(50% - 2px);
                float: left;
                height: 270px;
                overflow: hidden;
            }
            .images-wrapper {
                float: right;
                width: calc(50% - 2px);
                overflow: hidden;
                height: 270px;
            }
            .top-image, .images-wrapper {
                .ui.image {
                    @include image-responsive(cover);
                }
            }
        }
        @include breakpoint(computer){
            .top-image {
                height: 350px;
            }
            .images-wrapper {
                height: 350px;
            }
        }
        .middle-images {
            margin-top: 3px;
            @include breakpoint(tablet) {
                margin-top: 0;
            }
            .image {
                display: inline-block;
                width: calc(33.3% - 2px);
                margin-left: 1.5px;
                margin-right: 1.5px;
                height: 115px;
                .ui.image {
                    @include image-responsive(cover);
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                @include breakpoint(tablet) {
                    height: 135px;
                }
                @include breakpoint(computer) {
                    height: 175px;
                }
            }
        }
        .bottom-images {
            margin-top: 3px;
            .image {
                display: inline-block;
                width: calc(50% - 2px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                height: 130px;
                @include breakpoint(tablet) {
                    height: 135px;
                }
                @include breakpoint(computer) {
                    height: 175px;
                }
                .ui.image {
                    @include image-responsive(cover);
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
                .image-count-overlay {
                    margin-left: 1px;
                    margin-right: -1px;
                }
            }
            .last {
                position: relative;
                .ui.image {
                    @include image-responsive(cover);
                }
            }
        }
        //Two images gallery
        .two-images-gallery {
            .top-image {
                height: 160px;
            }
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                height: 160px;
                @include breakpoint(tablet){
                    height: auto;
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .three-images-gallery {
            .top-image {
                height: 200px;
                @include breakpoint(tablet){
                    height: 320px;
                }
                @include breakpoint(computer){
                    height: 400px;
                }
            }
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                @include breakpoint(tablet){
                    height: calc(160px - 1.5px);
                }
                @include breakpoint(computer){
                    height: calc(200px - 1.5px);
                }
                &:first-child {
                    margin-left: 0px;
                    @include breakpoint(tablet){
                        height: calc(160px - 1.5px);
                        margin-left: 1.5px;
                        margin-top: 0;
                    }
                    @include breakpoint(computer){
                        height: calc(200px - 1.5px);
                        margin-left: 1.5px;
                        margin-top: 0;
                    }
                }
                &:last-child {
                    margin-right: 0px
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .four-images-gallery {
            .image {
                display: inline-block;
                width: calc(50% - 1.5px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
                @include breakpoint(tablet){
                    height: 200px;
                }
                @include breakpoint(computer){
                    height: 300px;
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
        }
        .five-images-gallery {
            .top-images {
                .image {
                    display: inline-block;
                    width: calc(50% - 2px);
                    margin-right: 1.5px;
                    margin-left: 1.5px;
                    margin-top: 3px;
                    height: 120px;
                    @include breakpoint(tablet){
                        height: 170px;
                    }
                    @include breakpoint(computer){
                        height: 300px;
                    }
                }
            }
            .image {
                display: inline-block;
                width: calc(33.3% - 2px);
                margin-right: 1.5px;
                margin-left: 1.5px;
                margin-top: 3px;
                height: 120px;
                @include breakpoint(tablet){
                    height: 100px;
                }
                @include breakpoint(computer){
                    height: 250px;
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px
                }
            }
            .ui.image {
                @include image-responsive(cover);
            }
            .last {
                position: relative;
    
            }
        }
    }
}

//Single Media
.single-media {
    position: relative;
    margin: 0;
    .image-item {
        position: relative;
        padding-bottom: 80%;
        @include breakpoint(tablet) {
            padding-bottom: 66.6%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        //Progress bar
        .uploading-media {
            .ui.progress {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 999;
                &:first-child {
                    margin: 0!important;
                }
                .bar {
                    height: 1rem;
                }
            }
        }
    }
    .image-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        i.inverted.circular.grey.icon {
            background: rgba(255, 255, 255, 0.85) !important;
            color: $dark!important;
        }
    }
    //Circular image
    &.circular {
        .image-item {
            width: 80%;
            padding-bottom: 80%;
            @include breakpoint(tablet){
                width: 50%;
                padding-bottom: 50%;
            }
            margin-left: auto;
            margin-right: auto;
            img {
                border-radius: 100%;
            }
        }
        .image-edit {
            bottom: 1rem;
            left: 0;
            right: 0;
        }
        //Progress bar
        .uploading-media {
            .ui.progress {
                bottom: -1rem;
            }
        }
    }
}

//Video item
.video-item.image-item {
    width: 100%;
    position: relative;
    i.icon.play {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-size: 5rem;
        height: auto;
    }
    .image-edit {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        i.inverted.circular.grey.icon {
            background: rgba(255, 255, 255, 0.85) !important;
            color: $dark!important;
        }
    }
}

//Failed upload
.failed-upload {
    background: $grey-light;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width:100%;
    height: 100%;
    .failed-upload-icon {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;
        i.icon {
            font-size:2rem;
        }
    }
}

.single-media {
    .video-item.deletable {
        .image-delete {
            img {
                padding: 0.25rem;
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
