@import '../../css/mixins.scss';
@import '../../css/settings.scss';

.gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 1);
    z-index: 999999;
}

.custom-slider-gallery {
    //Fix modal outline border by setting to none
    .slick-slider.slick-initialized {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: -50%;
    }
    .slick-list {
        height: 100vh;
    }
}

.slider-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    padding: 0.5rem 0;
    line-height: 2;
    .ui.breadcrumb.custom-breadcrumb {
        a, .divider {
            color: #fff;
        }
    }
}

.slide-wrapper {
    .slide-img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
}

.slick-dots.slick-thumb {
    position: absolute;
    li {
        width: 75px;
        height: 65px;
        display: inline-block;
        margin: 5px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.ui.segment.image-infobox {
    position: fixed;
    bottom: 2rem;
    left: 0;
    right: 0;
    line-height: 1.2;
    border-radius: 5px;
    margin: 0 1rem;
}

.image-counter {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0.25rem 0;
    .counter {
        color: #fff;
        font-weight: 600;
        @include font-size(medium-s)
    }
}

.react-images__footer__count {
    color: #fff;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    right: 0;
    @include font-size(medium-s);
    text-align: center;
    padding: 0.25rem;
    font-weight: "600";
    max-width: 600px;
    margin: 0 auto;
    @include breakpoint(tablet){
        border-radius: 5px;
        bottom: 1rem;
    }
}

.react-images__navigation {
    button {
        background: rgba($grey-lighter, 0.75);
        svg {
            color: $dark;
        }
        &:hover {
            background: rgba($grey-lighter, 0.9);
        }
    }
}

.custom-captions {
    background: rgb(255, 255, 242);
    color: $dark;
    padding: 0.85rem;
    position: absolute;
    bottom: 3rem;
    left: 1rem;
    right: 1rem;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 5px;
    @include font-size(small-l);
    @include breakpoint(tablet){
        bottom: 4rem;
        left: 0;
        right: 0;
    }
}

.no-description {
    .ril__caption  {
        display: none;
    }
}

.ril__outer {
    background-color: rgba(0, 0, 0, 1);
}

.ril__caption {
    background: rgb(255, 255, 242);
    font-size: 0.875rem;
    display: block;
    position: absolute;
    bottom: 1rem;
    @include breakpoint(tablet){
        bottom: 2rem;
    }
    left: 14px;
    right: 14px;
    max-width: 600px;
    margin: 0 auto;
    border-radius: 4px;
    .ril__captionContent {
        color: $dark;
    }
}