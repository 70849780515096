@import "./mixins.scss";
@import "./settings.scss";

/* ************* Helper Classes *************** */
/* 

    1. Spacing
    2. Width / Height
    3. Text

*/

/* ************* Spacing *************** */

//Medium devices 
//Padding Top
.pt-0, .py-0 {
    padding-top: 0!important;
}

.pt-1, .py-1  {
    padding-top: 1rem!important;
}

.pt-2, .py-2 {
    padding-top: 2rem!important;
}

.pt-3, .py-3 {
    padding-top: 3rem!important;
}

.pt-4, .py-4 {
    padding-top: 4rem!important;
}

.pt-5, .py-5 {
    padding-top: 5rem!important;
}

.pt-6, .py-6 {
    padding-top: 6rem!important;
}

//Padding Bottom
.pb-0, .py-0 {
    padding-bottom: 0!important;
}

.pb-1, .py-1 {
    padding-bottom: 1rem!important;
}

.pb-2, .py-2 {
    padding-bottom: 2rem!important;
}

.pb-3, .py-3 {
    padding-bottom: 3rem!important;
}

.pb-4, .py-4 {
    padding-bottom: 4rem!important;
}

.pb-5, .py-5  {
    padding-bottom: 5rem!important;
}

.pb-6, .py-6 {
    padding-bottom: 6rem!important;
}

//Padding Left
.pl-0, .px-0 {
    padding-left: 0!important;
}

.pl-1, .px-1 {
    padding-left: 1rem!important;
}

.pl-2, .px-2 {
    padding-left: 2rem!important;
}

.pl-3, .px-3 {
    padding-left: 3rem!important;
}

.pl-4, .px-4 {
    padding-left: 4rem!important;
}

.pl-5, .px-5  {
    padding-left: 5rem!important;
}

.pl-6, .px-6 {
    padding-left: 6rem!important;
}

//Padding Right
.pr-0, .px-0 {
    padding-right: 0!important;
}

.pr-1, .px-1 {
    padding-right: 1rem!important;
}

.pr-2, .px-2 {
    padding-right: 2rem!important;
}

.pr-3, .px-3 {
    padding-right: 3rem!important;
}

.pr-4, .px-4 {
    padding-right: 4rem!important;
}

.pr-5, .px-5  {
    padding-right: 5rem!important;
}

.pr-6, .px-6 {
    padding-right: 6rem!important;
}

//Margin Top
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}

.mt-1, .my-1  {
    margin-top: 1rem!important;
}

.mt-2, .my-2 {
    margin-top: 2rem!important;
}

.mt-3, .my-3 {
    margin-top: 3rem!important;
}

.mt-4, .my-4 {
    margin-top: 4rem!important;
}

.mt-5, .my-5 {
    margin-top: 5rem!important;
}

.mt-6, .my-6 {
    margin-top: 6rem!important;
}

//Margin Bottom
.mb-1, .my-1 {
    margin-bottom: 1rem!important;
}

.mb-2, .my-2 {
    margin-bottom: 2rem!important;
}

.mb-3, .my-3 {
    margin-bottom: 3rem!important;
}

.mb-4, .my-4 {
    margin-bottom: 4rem!important;
}

.mb-5, .my-5  {
    margin-bottom: 5rem!important;
}

.mb-6, .my-6 {
    margin-bottom: 6rem!important;
}

//Mobile spacing
//Padding Top
@include breakpoint(mobile, tablet){

    .sm-pt-0, .sm-py-0 {
        padding-top: 0!important;
    }

    .sm-pt-1, .sm-py-1  {
        padding-top: 0.5rem!important;
    }

    .sm-pt-2, .sm-py-2 {
        padding-top: 1rem!important;
    }

    .sm-pt-3, .sm-py-3 {
        padding-top: 1.5rem!important;
    }

    .sm-pt-4, .sm-py-4 {
        padding-top: 2rem!important;
    }

    .sm-pt-5, .sm-py-5 {
        padding-top: 2.5rem!important;
    }

    .sm-pt-6, .sm-py-6 {
        padding-top: 3rem!important;
    }

    //Padding Bottom
    .sm-pb-0, .sm-py-0 {
        padding-bottom: 0!important;
    }

    .sm-pb-1, .sm-py-1 {
        padding-bottom: 0.5rem!important;
    }

    .sm-pb-2, .sm-py-2 {
        padding-bottom: 1rem!important;
    }

    .sm-pb-3, .sm-py-3 {
        padding-bottom: 1.5rem!important;
    }

    .sm-pb-4, .sm-py-4 {
        padding-bottom: 2rem!important;
    }

    .sm-pb-5, .sm-py-5  {
        padding-bottom: 2.5rem!important;
    }

    .sm-pb-6, .sm-py-6 {
        padding-bottom: 3rem!important;
    }

    .sm-pl-0, .sm-px-0 {
        padding-left: 0!important;
    }

    .sm-pr-0, .sm-px-0 {
        padding-right: 0!important;
    }

}


/* ************* Spacing *************** */


.w-100 {
    width: 100%!important;
}

.w-50 {
    width: 100%!important;
    @include breakpoint(tablet){
        width: 50%!important;
    }
}

.h-100 {
    height: 100%!important;
}

.h-50 {
    height: 50%!important;
}

/* ************* Text *************** */


.text-grey {
    color: $grey-dark;
}

.text-dark {
    color: $dark;
}

.text-red {
    color: $red-darker
}

.text-light, .text-white {
    color: #fff;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-transform-normal {
    text-transform: none!important;
}

.text-center {
    text-align: center;
}

@include breakpoint(mobile, tablet){
    .sm-text-center {
        text-align: center;
    }
}

/* ************* Background *************** */
.bg-grey-light {
    background: $grey-lighter;
}
